import { BookOpenIcon, CheckBadgeIcon, EyeIcon } from "@heroicons/react/16/solid";
import React, { useContext, useEffect, useState } from "react";
import { AppStore } from "../../App"
import booking from "../../services/booking.service";
import GlobalLoader from "../../components/GlobalLoader";

const HouseDetails = house => {

    const {store,dispatch} = useContext(AppStore)

    const handleTouch = e => {

        if(e.target == document.getElementById('add-view'))
        {
            dispatch({type : 'TOGGLE_BOOKING_DETAILS_INTERFACE',payload : false})
        }

    }

    return (

        <section onClick={handleTouch} id="add-view" className="fixed inset-0 bg-gray-900 bg-opacity-75 z-50 flex items-center justify-center">

            <article className="lg:w-7/12 bg-white p-4 overflow-y-auto h-[350px]">

                <header className="font-extrabold text-lg">
                    Details de la maison
                </header>

                <div className="">

                    {/* List all the features here */}

                    <div className="my-3">
                        <b className="">Nombre de chambres : </b> <span className="">{store.bookings.details.rooms}</span>
                    </div>
                    <div className="my-3">
                        <b className="">Nombre de salons : </b> <span className="">{store.bookings.details.saloon}</span>
                    </div>
                    <div className="my-3">
                        <b className="">Nombre de douches : </b> <span className="">{store.bookings.details.bath}</span>
                    </div>

                </div>

                <div className="">

                    <header className="font-extrabold">
                        Gallerie d'images
                    </header>

                    <div className="grid grid-cols-3 gap-5 pt-5">
                        {
                            store.bookings.details.gallery.map(element => (

                                <img src={element.pic} className=""/>

                            ))
                        }
                    </div>

                </div>

            </article>

        </section>

    )

}

export default function Reservations()
{

    const {store,dispatch} = useContext(AppStore)

    const fetchBookings = async () => {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        booking.getBookings()
        .then(response => {

            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

            if(response)
            {

                // alert(JSON.stringify(response))

                if(response.status == 200)
                {
                    // Dispatch the bookings
                    dispatch({type : 'SET_BOOKINGS',payload : response.data.bookings})
                }
                else{
                    console.log(response)
                }

            }

        })

    }

    const validateBooking = async id => {

        if(window.confirm('Voulez vous declarer cette reservation comme traitee ?'))
        {

            dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

            booking.validateBooking(id)
            .then(response => {

                dispatch({type : 'TOGGLE_APP_LOADING', payload : false})
    
                if(response)
                {
    
                    if(response.status == 200)
                    {
                        alert('Reservation traite avec succes')
                        dispatch({type : 'SET_BOOKINGS',payload : response.data.bookings})
                    }
                    else{
                        console.log(response)
                    }
    
                }
    
            })

        }

    }

    const callDetailsInterface = house => {

        dispatch({type : 'SET_DETAILS',payload : house})

        dispatch({type : 'TOGGLE_BOOKING_DETAILS_INTERFACE',payload : true})
        
    }

    useEffect(() => {

        fetchBookings()

    },[])

    return (

        <section className="w-full">

            <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg mb-14 flex justify-between">
                <div className="">
                    <BookOpenIcon className="w-6 inline-flex text-black"/>
                    <span className="font-bold pl-2 text-black">Reservations</span>
                </div>
            </header>

            {
                store.bookings.detailsInterfaceVisible && <HouseDetails/>
            }

            {store.loading && <GlobalLoader/>}

            {
                !store.loading && 

                <div className="">

                    {
                        store.bookings.list.length == 0 && 
                        <div className="">
                            Aucune reservation en cours
                        </div>
                    }

                    {
                        store.bookings.list.length > 0 && 
                        <div className="">
                                
                            <table className="w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    #
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Client
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    N Telephone
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Type
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Duree
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Montant paye
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Action
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                {store.bookings.list?.map((booking,index) => (
                                    <tr key={booking.id}>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                            {index + 1}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{booking?.user?.c_name}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <span className="bg-orange-700 font-bold text-xs rounded-lg shadow-xl p-1 text-white">+229 {booking?.user?.phone}</span>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{booking?.property?.type}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{booking?.number_of_days} jours</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{booking?.amount}</td>
                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex gap-2">
                                            <button onClick={e => callDetailsInterface(booking?.house)} title="Details" className="bg-orange-800 text-white font-semibold p-2 rounded-md flex items-center gap-2">
                                                <EyeIcon className="w-4"/>
                                            </button>
                                            <button onClick={e => validateBooking(booking.id)} className="bg-blue-800 text-white font-semibold p-2 rounded-md flex items-center gap-2">
                                                <CheckBadgeIcon className="w-4"/>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>

                        </div>
                    }

                </div>

            }

        </section>

    )

}