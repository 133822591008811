import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import useRequest from "../../hooks/useRequest";
import { GET_CARS_COUNT, GET_PUBS, GET_PUBS_COUNT, GET_RESERVATIONS_COUNT, GET_USER_COUNT, QUEUED_PARTNERS } from "../../resources/routes";
import { AuthContext } from "../../services/AuthProvider.service";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from "../../components/Spinner";

import {
    NewspaperIcon,
    UserIcon,
    LinkIcon,
    TruckIcon,
    BookOpenIcon
  } from '@heroicons/react/24/outline'
import GlobalLoader from "../../components/GlobalLoader";
import { AppStore } from "../../App";


export default function Home({ctx_memo}){

    const {store,dispatch} = useContext(AppStore)

    const {getData} = useRequest()

    // const [userCount,setUserCount] = useState(0)

    // const [pubCount,setPubCount] = useState(0)

    // const [carCount,setCarCount] = useState(0)

    // const [reservationsCount,setReservationsCount] = useState(0)

    const [loading,setLoading] = useState(false)

    const fetch_user_count = async () => {

        setLoading(true)

        let response = await getData(GET_USER_COUNT)

        if(response.status == 200) dispatch({type : 'SET_USER_COUNT',payload : response.data.count})

    }

    const fetch_pubs_count = async () => {

        let response = await getData(GET_PUBS_COUNT)

        if(response.status == 200) dispatch({type : 'SET_PUB_COUNT',payload : response.data.count})
        else toast.error('Something went wrong')

    }

    const fetch_cars_count = async () => {

        let response = await getData(GET_CARS_COUNT)

        if(response.status == 200) dispatch({type : 'SET_CAR_COUNT',payload : response.data.count})

    }

    const fetch_reservations_count = async () => {

        let response = await getData(GET_RESERVATIONS_COUNT)

        if(response.status == 200) dispatch({type : 'SET_RESERVATION_COUNT',payload : response.data.count})

    }

    const load_data = async () => {

        await fetch_user_count()

        .then(async () => await fetch_pubs_count()
            .then( async () => await fetch_cars_count()
            .then(async () => await fetch_reservations_count()
            .then(() => setLoading(false)))) 
        )

    }

    useEffect(() => {

        load_data()

    },[])

    return (

        <section className="w-full">

            <ToastContainer/>

            {
                loading && <GlobalLoader/>
            }

            <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg">
                <NewspaperIcon className="w-6 inline-flex text-black"/>
                <span className="font-bold pl-2 text-black">Tableau de bord</span>
            </header>

            <article className="py-10 grid grid-cols-4 gap-6">

                <div className="shadow p-4 shadow-current bg-[#004665] rounded">

                    <div className="">
                        <UserIcon className="w-6 inline-flex text-[#2c82a7]"/>
                        <span className="font-bold pl-2 text-[#2c82a7]">Utilisateurs</span>
                    </div>

                    <div className="text-[#2c82a7] py-4 font-extrabold text-2xl">
                        <span>{store.userCount}</span>
                    </div>

                </div>

                <div className="shadow p-4 shadow-current bg-[#ffce00] rounded">

                    <div className="">
                        <LinkIcon className="w-6 inline-flex text-[#726328]"/>
                        <span className="font-bold pl-2 text-[#726328]">Publicités</span>
                    </div>

                    <div className="text-[#726328] py-4 font-bold text-2xl">
                        <span>{store.pubCount}</span>
                    </div>

                </div>

                <div className="shadow p-4 shadow-current bg-[#2890b9] rounded">

                    <div className="">
                        <TruckIcon className="w-6 inline-flex text-[#174052]"/>
                        <span className="font-bold pl-2 text-[#174052]">Voitures</span>
                    </div>

                    <div className="text-[#174052] py-4 font-extrabold text-2xl">
                        <span>{store.carCount}</span>
                    </div>

                </div>

                <div className="shadow p-4 shadow-current bg-[#d48047] rounded">

                    <div className="">
                        <BookOpenIcon className="w-6 inline-flex text-[#96572e]"/>
                        <span className="font-bold pl-2 text-[#96572e]">Réservations</span>
                    </div>

                    <div className="text-[#96572e] py-4 font-extrabold text-2xl">
                        <span>{store.reservationsCount}</span>
                    </div>

                </div>

            </article>

        </section>      

    )

}