import React, { useContext, useState } from "react";
import useRequest from "../../hooks/useRequest";
import { CREATE_VENTURE, GET_VENTURES } from "../../resources/routes";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import GlobalLoader from "../GlobalLoader";
import { AppStore } from "../../App";

export default function AddVenture({display_context,sector_context,venture_context})
{

    const {store,dispatch} = useContext(AppStore)

    const {postData,getData} = useRequest()

    const [name,setName] = useState(null)
    const [email,setEmail] = useState(null)
    const [phone,setPhone] = useState(null)
    const [location,setLocation] = useState(null)
    const [_sector,_setSector] = useState(null)

    const handleTouch = e => {

        if(e.target == document.getElementById('add-view'))
        {
            dispatch({type : 'SET_ENTERPRISES_INTERFACE_VISIBLE', payload : false})
        }

    }

    const fetch_ventures = async () => {

        let response = await getData(GET_VENTURES)

        if(response.status == 200)
        {
            dispatch({type : 'SET_ENTERPRISES', payload : response.data.ventures})
        }
        else{
            toast.error('Ventures fetching went wrong')
        }

    }

    const handleSubmit = async e => {

        e.preventDefault()

        dispatch({type : 'SET_EMPLOYMENT_LOADING', payload : true})

        let data = {
            name : name,
            sector : _sector,
            phone : phone,
            email : email,
            location : location
        }

        let response = await postData(data,CREATE_VENTURE)

        if(response.status == 200)
        {
            // Toast + fetch ventures
            fetch_ventures().then(() => {toast.success('Entreprise ajoutée avec succès');dispatch({type : 'SET_ENTERPRISES_INTERFACE_VISIBLE', payload : false});dispatch({type : 'SET_EMPLOYMENT_LOADING', payload : false});})            

        }
        else{
            // Toast
            dispatch({type : 'SET_EMPLOYMENT_LOADING', payload : false})
            toast.error('Something went wrong')
        }

    }

    return (

        <section onClick={handleTouch} id="add-view" className="fixed inset-0 bg-gray-900 bg-opacity-75 z-50 flex items-center justify-center">

            <article className="lg:w-7/12 bg-white p-4 overflow-y-auto h-[400px] overflow-scroll">

                <ToastContainer/>

                {store.employment.loading && <GlobalLoader/>}

                <form onSubmit={handleSubmit}>

                    <div className="pb-4">
                        <label className="block py-4 font-extrabold">Nom de l'entreprise</label>
                        <input type="text" required name="name" onChange={e => setName(e.target.value)} className="bg-[#e6e6e6] p-2 w-full focus:outline-none"  placeholder="Nom de l'entreprise"/>
                    </div>

                    <div className="pb-4">
                        <label className="block py-4 font-extrabold">Secteur d'activité</label>
                        <select required className="bg-[#e6e6e6] p-2 w-full focus:outline-none" onChange={e => _setSector(e.target.value)}>

                            <option>Secteur d'activité</option>

                            {
                                store.employment.sectors.list.map(sector => (
                                    <option value={sector.id}>{sector.name}</option>
                                ))
                            }

                        </select>
                    </div>

                    <div className="pb-4">
                        <label className="block py-4 font-extrabold">Numéro de téléphone <span className="text-xs text-red-700">(facultatif)</span></label>
                        <input type="text" onChange={e => setPhone(e.target.value)} className="bg-[#e6e6e6] p-2 w-full focus:outline-none"  placeholder="Numéro de téléphone"/>
                    </div>

                    <div className="pb-4">
                        <label className="block py-4 font-extrabold">Email <span className="text-xs text-red-700">(facultatif)</span></label>
                        <input type="email" onChange={e => setEmail(e.target.value)} className="bg-[#e6e6e6] p-2 w-full focus:outline-none"  placeholder="Email"/>
                    </div>

                    <div className="pb-4">
                        <label className="block py-4 font-extrabold">Localisation <span className="text-xs text-red-700">(facultatif)</span></label>
                        <input type="text" onChange={e => setLocation(e.target.value)} className="bg-[#e6e6e6] p-2 w-full focus:outline-none"  placeholder="Localisation"/>
                    </div>

                    <div>
                        <button type="submit" className="bg-[#2e7ba5] p-3 pr-10 text-white">Ajouter</button>
                    </div>

                </form>

            </article>

        </section>

    )

}