import React, { useContext, useState } from "react";
import useRequest from "../../hooks/useRequest";
import { CREATE_SECTOR, GET_SECTORS } from "../../resources/routes";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GlobalLoader from "../GlobalLoader";
import { AppStore } from "../../App";

export default function AddSector()
{

    const {store,dispatch} = useContext(AppStore)

    const [name,setName] = useState(null)

    const {postData,getData} = useRequest()

    const fetch_sectors = async () => {

        dispatch({type : 'SET_SECTOR_INTERFACE_VISIBLE', payload : true})

        let response = await getData(GET_SECTORS)

        if(response.status == 200)
        {
            dispatch({type : 'SET_SECTORS', payload : response.data.sectors})
        }
        else{
            toast.error('Sectors fetching went wrong')

            alert(JSON.stringify(response))
        }

    }

    const handleTouch = e => {

        if(e.target == document.getElementById('add-view'))
        {
            dispatch({type : 'SET_SECTOR_INTERFACE_VISIBLE', payload : false})
        }

    }

    const handleSubmit = async e => {

        dispatch({type : 'SET_EMPLOYMENT_LOADING', payload : true})

        e.preventDefault()

        let data = {
            name : name
        }

        let response = await postData(data,CREATE_SECTOR)

        if(response.status == 200)
        {
            // fetch sectors
            // Toast
            fetch_sectors()
            dispatch({type : 'SET_EMPLOYMENT_LOADING', payload : false})
            toast.success('Secteur ajouté avec succès')
        }
        else{
            // Toast
            dispatch({type : 'SET_EMPLOYMENT_LOADING', payload : false})
            toast.error('Something went wrong')
        }

        dispatch({type : 'SET_SECTOR_INTERFACE_VISIBLE', payload : false})

    }

    return (

        <section onClick={handleTouch} id="add-view" className="fixed inset-0 bg-gray-900 bg-opacity-75 z-50 flex items-center justify-center">

            <ToastContainer/>

            {store.employment.loading && <GlobalLoader/>}

            <article className="lg:w-7/12 bg-white p-4 overflow-y-auto h-[200px]">

                <form onSubmit={handleSubmit}>

                    <div className="pb-4">
                        <label className="block py-4 font-extrabold">Secteur d'activité</label>
                        <input type="text" name="name" onChange={e => setName(e.target.value)} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"  placeholder="Secteur d'activité"/>
                    </div>

                    <div>
                        <button type="submit" className="bg-[#2e7ba5] p-3 pr-10 text-white">Ajouter</button>
                    </div>

                </form>

            </article>

        </section>

    )

}