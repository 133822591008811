import React, { useContext, useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'
import useRequest from "../../hooks/useRequest";
import { CREATE_ADS, GET_ADS } from "../../resources/routes";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GlobalLoader from "../GlobalLoader";
import { FORM_DATA } from "../../resources/types";
import { AppStore } from "../../App";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const people = [


    { id: 1, name: 'Leslie Alexander' },
    // More users...
  ]

export default function CreateAds({display_context,ventures_ctx,ads_ctx})
{

    const {store,dispatch} = useContext(AppStore)

    const [query, setQuery] = useState('')
    const [selectedPerson, setSelectedPerson] = useState(null)

    const [content,setContent] = useState(null)

    const [file,setFile] = useState(null)

    const {postData,getData} = useRequest()

    const filteredPeople =
    query === ''
      ? store.employment.enterprises.list
      : store.employment.enterprises.list.filter((venture) => {
          return venture.name.toLowerCase().includes(query.toLowerCase())
        })

    const handleTouch = e => {

        if(e.target == document.getElementById('add-view'))
        {
            dispatch({type : 'SET_ADS_INTERFACE_VISIBLE', payload : false})
        }

    }

    const fetch_ads = async () => {

        dispatch({type : 'SET_EMPLOYMENT_LOADING', payload : false})

        let response = await getData(GET_ADS)

        // alert(JSON.stringify(response))

        if(response.status == 200)
        {
            dispatch({type : 'SET_ADS', payload : response.data.ads})
        }
        else{
            toast.error('Ads fetching went wrong')
        }

    }

    const convert_to_base64 = async (file) => {

        return new Promise((resolve, reject) => {
          const reader = new FileReader();
      
          reader.onloadend = () => {
            // The result will be the base64-encoded string
            // setSrc(reader.result)
            resolve(reader.result);
          };
      
          reader.onerror = () => {
            reject(new Error('Error reading file as base64'));
          };
      
          // Read the file as a data URL (base64)
          reader.readAsDataURL(file);
        });

    }

    const handleFileChange = async e => {

        setFile(e.target.files[0])

    }

    const handleSubmit = async e => {

        e.preventDefault()

        dispatch({type : 'SET_EMPLOYMENT_LOADING', payload : true})

        let data = {
            venture : selectedPerson.id,
            content : content,
            document : file
        }

        // alert(JSON.stringify(selectedPerson.id))

        let form = new FormData();

            for (let key in data) {
                if (data.hasOwnProperty(key) && key !== 'files') {
                    form.append(key, data[key]);
                }
            }

        let response = await postData(form,CREATE_ADS,FORM_DATA)

        if(response.status == 200)
        {

            fetch_ads()
            .then(() => dispatch({type : 'SET_EMPLOYMENT_LOADING', payload : false}))
            .then(() => toast.success('Annonce ajoutée avec succès'))
            .then(() => dispatch({type : 'SET_ADS_INTERFACE_VISIBLE', payload : false}))

        }
        else{
            alert(JSON.stringify(response))
            dispatch({type : 'SET_EMPLOYMENT_LOADING', payload : false})
            toast.error('Something went wrong')
        }

    }

    return (

        <section onClick={handleTouch} id="add-view" className="fixed inset-0 bg-gray-900 bg-opacity-75 z-50 flex items-center justify-center">

            <article className="lg:w-7/12 bg-white p-4 overflow-y-auto h-[450px]">

                {store.employment.loading && <GlobalLoader/>}

                <ToastContainer/>

                <form onSubmit={handleSubmit}>

                    <div className="pb-4">
                        <Combobox as="div" value={selectedPerson} onChange={setSelectedPerson}>
                            <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">Entreprise</Combobox.Label>
                            <div className="relative mt-2">
                                <Combobox.Input
                                className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:outline-none sm:text-sm sm:leading-6"
                                onChange={(event) => setQuery(event.target.value)}
                                displayValue={(venture) => venture?.name}
                                />
                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </Combobox.Button>

                                {store.employment.enterprises.list.length > 0 && (
                                <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm">
                                    {store.employment.enterprises.list.map((venture) => (
                                    <Combobox.Option
                                        key={venture.id}
                                        value={venture}
                                        className={({ active }) =>
                                        classNames(
                                            'relative cursor-default select-none py-2 pl-3 pr-9',
                                            active ? 'bg-[#004665] text-white' : 'text-gray-900'
                                        )
                                        }
                                    >
                                        {({ active, selected }) => (
                                        <>
                                            <span className={classNames('block truncate', selected && 'font-semibold')}>{venture.name}</span>

                                            {selected && (
                                            <span
                                                className={classNames(
                                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                                active ? 'text-white' : 'text-indigo-600'
                                                )}
                                            >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                            )}
                                        </>
                                        )}
                                    </Combobox.Option>
                                    ))}
                                </Combobox.Options>
                                )}
                            </div>
                        </Combobox>
                    </div>

                    <div className="pb-4">
                        <label className="block py-4 font-extrabold">Contenu de l'annonce</label>
                        <textarea type="text" required name="name" onChange={e => setContent(e.target.value)} className="bg-[#e6e6e6] p-2 w-full focus:outline-none"  placeholder="Contenu de l'annonce"/>
                    </div>

                    <div className="pb-4">
                        <label className="block py-4 font-extrabold">Fichier supplémentaire <span className="text-xs text-red-700">(facultatif)</span></label>
                        <input type="file" name="name" onChange={handleFileChange} className="bg-[#e6e6e6] p-2 w-full focus:outline-none"/>
                    </div>

                    <div>
                        <button type="submit" className="bg-[#2e7ba5] p-3 pr-10 text-white">Ajouter</button>
                    </div>

                </form>

            </article>

        </section>

    )

}