import React from "react";
import { getData, postData } from "../lib/request";
import { ACTIVATE_PERSONALIZED_COMMISSION, BROADCAST_MESSAGES, FILTER_USER_BY_ROLE, GET_ONLINE_USERS, GET_ROLES, GET_USER, LOCK_USER_ACCOUNT, SET_DRIVER_COMMISSION } from "../resources/routes";


export class userService {


    static async getUser(data)
    {

        let response = await getData(GET_USER + '/' + data.id)

        return response

    }

    static async filterByRole(data)
    {

        let response = await postData(data,FILTER_USER_BY_ROLE)

        return response

    }

    static async getRoles()
    {

        let response = await getData(GET_ROLES)

        return response

    }

    static async activatePersonalizedCommission(data)
    {

        let response = await postData(data,ACTIVATE_PERSONALIZED_COMMISSION)

        return response

    }

    static async lockUserAccount(data)
    {

        let response = await postData(data,LOCK_USER_ACCOUNT)

        return response

    }

    static async broadcastMessage(data)
    {

        let response = await postData(data,BROADCAST_MESSAGES)

        return response

    }

    static async getOnlineUsers(data)
    {

        let response = await postData(data,GET_ONLINE_USERS)

        return response

    }

    static async setDriverCommission(data)
    {

        let response = await postData(data,SET_DRIVER_COMMISSION + '/' + data.user_id)

        return response

    }

}