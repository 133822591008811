import React from "react";
import "../resources/spinner.css"

export default function GlobalLoader()
{

    return (

        <section className="fixed inset-0 bg-gray-900 bg-opacity-75 z-50 flex items-center justify-center">

            <span className="loader"></span>

        </section>

    )

}