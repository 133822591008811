import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import useRequest from "../../hooks/useRequest"
import { GET_SUBSCRIPTIONS } from "../../resources/routes"
import { PencilIcon, ShoppingBagIcon, TrashIcon } from "@heroicons/react/24/outline"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from "../../components/Spinner"

export default function ProductSubscriptions({ctx_memo})
{

    const [loading,setLoading] = useState(false)

    const [subscriptions,setSubscriptions] = useState([])

    const attrib = useParams()

    const {postData,getData} = useRequest()

    const fetch_subscriptions = async () => {

        setLoading(true)

        let response = await getData(`${GET_SUBSCRIPTIONS}/${attrib.id}`)

        if(response.status == 200)
        {
            // alert(JSON.stringify(response.data.subscriptions))
            setSubscriptions(response.data.subscriptions)
        }
        else{
            alert(JSON.stringify(response))
        }

        setLoading(false)

    }

    useEffect(() => {

        fetch_subscriptions()

    },[])

    return (

        <>
        
            <section className="w-full">

                <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg mb-14 flex items-center justify-between">
                    <div className="">
                        <ShoppingBagIcon className="w-6 inline-flex text-black"/>
                        <span className="font-bold pl-2 text-black">Souscriptions pour le produit : {attrib.id == 2 ? 'Épargne' : 'Assurance vie'}</span>
                    </div>
                </header>

                <ToastContainer/>

                {loading ? <Spinner/> : 

                    <article className="w-full">

                    {
                        subscriptions.length == 0 ?

                        <div>Aucun partenaire en attente</div> : 

                        <table className="w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                #
                                </th>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Client
                                </th>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Objectif
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Action
                                </th>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                            {subscriptions.map(subscription => (
                                <tr key={subscription.id}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {subscription.id}
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                        {subscription.user?.c_name}
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                        {subscription.target_amount}
                                    </td>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex gap-3">
                                        <a href=""  className="text-blue-600 hover:text-blue-900">
                                            <PencilIcon className="w-6"/>
                                        </a>
                                        <a href="" className="text-red-600 hover:text-red-900">
                                            <TrashIcon className="w-6"/>
                                        </a>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                    }

                    </article>                
                
                }

            </section>

        </>

    )

}