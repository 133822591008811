

const initalState = {
    userCount : 0,
    pubCount : 0,
    carCount : 0,
    reservationsCount : 0,
    partners : [],
    users : [],
    usersMemory : [],
    roles : [],
    pubs : [],
    userDetails : {
        infos : {},
        zoomActive : false
    },
    pubsPage : {
        indicators : {
            addViewUp : false,
            loading : false
        }
    },
    products : {
        list : [],
        loading : false,
        addInterfaceVisible : false
    },
    employment : {
        sectors : {
            list : [],
            interfaceVisible : false,
        },
        enterprises : {
            list : [],
            interfaceVisible : false,
            update : {
                interfaceVisible : false,
                memory : {}
            }
        },
        ads : {
            list : [],
            interfaceVisible : false
        },
        loading : false,
    },
    transactions : {
        withdrawals : [],
        transactions : [],
        loading : false
    },
    settings : {
        categories : [],
        majorations : {
            list : [],
            updateMemory : null,
            createInterfaceState : false,
            updateInterfaceState : false,
            createMemory : null
        },
        loading : false
    },
    computability : {
        turnovers : []
    },
    promo : {
        list : [],
        memory : {},
        addInterfaceVisible : false
    },
    errands : {
        list : [],
        listIndex : 0,
        singleViewData : {}
    },
    categories : {
        list : [],
        creationInterfaceVisible : false,
        updateInterfaceVisible : false,
        categoryToUpdate : {}
    },
    taxes : {
        houses : [],
        historyInterfaceVisible : false,
        history : {}
    },
    bookings : {
        list : [],
        detailsInterfaceVisible : false,
        details : {}
    },
    pendingCars : [],
    loading : false
}

const reduce = (state,action) => {

    switch (action.type) {

        case 'SET_PENDING_CARS' :

            return {
                ...state,
                pendingCars : action.payload
            }
            
        break;

        case 'TOGGLE_APP_LOADING':

            return {
                ...state,
                loading : action.payload
            }
            
        break;

        // Bookings

        case 'SET_BOOKINGS':

            return {
                ...state,
                bookings : {
                    ...state.bookings,
                    list : action.payload
                }
            }
            
        break;

        case 'SET_DETAILS':

            return {
                ...state,
                bookings : {
                    ...state.bookings,
                    details : action.payload
                }
            }
            
        break;

        case 'TOGGLE_BOOKING_DETAILS_INTERFACE':

            return {
                ...state,
                bookings : {
                    ...state.bookings,
                    detailsInterfaceVisible : action.payload
                }
            }
            
        break;

        // Taxes

        case 'SET_TAXABLE_HOUSES':

            return {
                ...state,
                taxes : {
                    ...state.taxes,
                    houses : action.payload
                }
            }
            
        break;

        case 'SET_TAXE_HISTORY':

            return {
                ...state,
                taxes : {
                    ...state.taxes,
                    history : action.payload
                }
            }
            
        break;

        case 'TOGGLE_HISTORY_INTERFACE':

            return {
                ...state,
                taxes : {
                    ...state.taxes,
                    historyInterfaceVisible : action.payload
                }
            }
            
        break;

        // User details

        case 'SET_USER_INFOS':

            return {
                ...state,
                userDetails : {
                    ...state.userDetails,
                    infos : action.payload
                }
            }
            
        break;

        case 'SET_ZOOM':

            return {
                ...state,
                userDetails : {
                    ...state.userDetails,
                    zoomActive : action.payload
                }
            }
            
        break;

        case 'SET_ROLES':

            return {
                ...state,
                roles : action.payload
            }
            
        break;

        case 'SET_USERS_MEMORY':

            return {
                ...state,
                usersMemory : action.payload
            }
            
        break;

        case 'SET_USER_COUNT':

            return {
                ...state,
                userCount : action.payload
            }
            
        break;

        case 'SET_PUB_COUNT':

            return {
                ...state,
                pubCount : action.payload
            }
        
        break;

        case 'SET_CAR_COUNT':

            return {
                ...state,
                carCount : action.payload
            }
        
        break;

        case 'SET_RESERVATION_COUNT':

            return {
                ...state,
                reservationsCount : action.payload
            }    
        
        break;

        case 'SET_USERS':

            return {
                ...state,
                users : action.payload
            }    
        
        break;

        case 'SET_PARTNERS':

            return {
                ...state,
                partners : action.payload
            }    
        
        break;

        // Pub page things

        case 'SET_PUBS':

            return {
                ...state,
                pubs : action.payload,
                pubsPage : {
                    ...state.pubsPage,
                    indicators : {
                        ...state.pubsPage.indicators,
                        loading : false
                    }
                }
            }    
        
        break;

        case 'TOGGLE_PUB_PAGE_ADD_VIEW':

            return {
                ...state,
                pubsPage : {
                    ...state.pubsPage,
                    indicators : {
                        ...state.pubsPage.indicators,
                        addViewUp : action.payload
                    }
                }
            }    
        
        break;

        case 'TOGGLE_PUB_PAGE_LOADING':

            return {
                ...state,
                pubsPage : {
                    ...state.pubsPage,
                    indicators : {
                        ...state.pubsPage.indicators,
                        loading : action.payload
                    }
                }
            }    
        
        break;

        // Handle products

        case 'SET_PRODUCTS':

            return {
                ...state,
                products : {
                    ...state.products,
                    list : action.payload
                }
            }    
        
        break;

        // Employment

        case 'SET_SECTORS':

            return {
                ...state,
                employment : {
                    ...state.employment,
                    sectors : {
                        ...state.employment.sectors,
                        list : action.payload
                    }
                }
            }    
        
        break;

        case 'SET_ENTERPRISES':

            return {
                ...state,
                employment : {
                    ...state.employment,
                    enterprises : {
                        ...state.employment.enterprises,
                        list : action.payload
                    }
                }
            }    
        
        break;

        case 'SET_ADS':

            return {
                ...state,
                employment : {
                    ...state.employment,
                    ads : {
                        ...state.employment.ads,
                        list : action.payload
                    }
                }
            }    
        
        break;

        case 'SET_SECTOR_INTERFACE_VISIBLE':

            return {
                ...state,
                employment : {
                    ...state.employment,
                    sectors : {
                        ...state.employment.sectors,
                        interfaceVisible : action.payload
                    }
                }
            }    
        
        break;

        case 'SET_ENTERPRISES_INTERFACE_VISIBLE':

            return {
                ...state,
                employment : {
                    ...state.employment,
                    enterprises : {
                        ...state.employment.enterprises,
                        interfaceVisible : action.payload
                    }
                }
            }    
        
        break;

        case 'SET_ADS_INTERFACE_VISIBLE':

            return {
                ...state,
                employment : {
                    ...state.employment,
                    ads : {
                        ...state.employment.ads,
                        interfaceVisible : action.payload
                    }
                }
            }    
        
        break;

        case 'SET_EMPLOYMENT_LOADING':

            return {
                ...state,
                employment : {
                    ...state.employment,
                    loading : action.payload
                }
            }    
        
        break;

        case 'SAVE_UPDATE_DATA':

            return {
                ...state,
                employment : {
                    ...state.employment,
                    enterprises : {
                        ...state.employment.enterprises,
                        update : {
                            interfaceVisible : action.payload.status,
                            memory : action.payload.data
                        }
                    }
                }
            }    
        
        break;

        case 'TOGGLE_ENTERPRISE_UPDATE_INTERFACE':

            return {
                ...state,
                employment : {
                    ...state.employment,
                    enterprises : {
                        ...state.employment.enterprises,
                        update : {
                            ...state.employment.enterprises.update,
                            interfaceVisible : action.payload,
                        }
                    }
                }
            }    
        
        break;

        // Products handling

        case 'TOGGLE_PRODUCT_PAGE_LOADER':

            return {
                ...state,
                products : {
                    ...state.products,
                    loading : action.payload
                }
            }    
        
        break;

        case 'TOGGLE_PRODUCT_ADD_INTERFACE':

            return {
                ...state,
                products : {
                    ...state.products,
                    addInterfaceVisible : action.payload
                }
            }    
        
        break;

        // Transactions handleling

        case 'SET_WITHDRAWALS':

            return {
                ...state,
                transactions : {
                    ...state.transactions,
                    withdrawals : action.payload
                }
            }   
        
        break;

        case 'SET_TRANSACTIONS':

            return {
                ...state,
                transactions : {
                    ...state.transactions,
                    transactions : action.payload
                }
            }    
        
        break;

        // App settings state

        case 'SET_LOCOMOTION_CATEGORIES':

            return {
                ...state,
                settings : {
                    ...state.settings,
                    categories : action.payload
                }
            }    
        
        break;

        case 'SET_MAJORATIONS':

            return {
                ...state,
                settings : {
                    ...state.settings,
                    majorations : {
                        ...state.settings.majorations,
                        list : action.payload
                    }
                }
            }    
        
        break;

        case 'TOGGLE_MAJORATION_INTERFACE':

            return {
                ...state,
                settings : {
                    ...state.settings,
                    majorations : {
                        ...state.settings.majorations,
                        createInterfaceState : action.payload.status,
                        createMemory : action.payload.cache
                    }
                }
            }    
        
        break;

        case 'TOGGLE_MAJORATION_UPDATE_INTERFACE':

            return {
                ...state,
                settings : {
                    ...state.settings,
                    majorations : {
                        ...state.settings.majorations,
                        updateInterfaceState : action.payload.status,
                        updateMemory : action.payload.cache
                    }
                }
            }    
        
        break;

        // promo

        case 'SET_DISCOUNTS':

            return { 
                ...state,
                promo : {
                    ...state.promo,
                    list : action.payload
                }
            }    
        
        break;

        case 'TOGGLE_ADD_PROMO_INTERFACE':

            return {
                ...state,
                promo : {
                    ...state.promo,
                    addInterfaceVisible : action.payload
                }
            }    
        
        break;

        // Errands

        case 'SAVE_ERRANDS':

            return {
                ...state,
                errands : {
                    ...state.errands,
                    list : action.payload
                }
            }    
        
        break;

        case 'SET_ERRAND_LIST_INDEX':

            return {
                ...state,
                errands : {
                    ...state.errands,
                    listIndex : action.payload
                }
            }    
        
        break;

        case 'SET_SINGLE_VIEW':

            return {
                ...state,
                errands : {
                    ...state.errands,
                    singleViewData : action.payload
                }
            }    
        
        break;

        // Categories

        case 'TOGGLE_CATEGORY_CREATION_INTERFACE':

            return {
                ...state,
                categories : {
                    ...state.categories,
                    creationInterfaceVisible : action.payload
                }
            }    
        
        break;

        case 'SET_CATEGORIES':

            return {
                ...state,
                categories : {
                    ...state.categories,
                    list : action.payload
                }
            }    
        
        break;

        case 'SET_CATEGORY_TO_UPDATE':

            return {
                ...state,
                categories : {
                    ...state.categories,
                    categoryToUpdate : action.payload
                }
            }    
        
        break;

        case 'TOGGLE_CATEGORY_UPDATE_INTERFACE':

            return {
                ...state,
                categories : {
                    ...state.categories,
                    updateInterfaceVisible : action.payload
                }
            }    
        
        break;
    
        default:
            break;
    }

}

export {reduce,initalState}