import { getData, postData } from "../lib/request"
import { CREATE_MAJORATION, DELETE_MAJORATION, GET_MAJORATIONS, UPDATE_MAJORATION } from "../resources/routes.js"

export default class majorations {

    static async getMajorations(){

        let route = GET_MAJORATIONS

        let response = await getData(route)

        return response

    }

    static async createMajoration(data)
    {

        let route = CREATE_MAJORATION

        let response = await postData(data,route)

        return response

    }

    static async updateMajoration(data)
    {

        let route = UPDATE_MAJORATION

        let response = await postData(data,route)

        return response

    }

    static async deleteMajoration(data)
    {

        let route = DELETE_MAJORATION

        let response = await postData(data,route)

        return response

    }

}