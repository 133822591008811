import { useContext, useState } from "react";
import { AppStore } from "../../App"
import GlobalLoader from "../GlobalLoader"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import majorations from "../../services/majoration.service";

export default function CreateMajoration()
{

    const {store,dispatch} = useContext(AppStore)

    const [t1,setT1] = useState(null)
    const [t2,setT2] = useState(null)
    const [majoration,setMajoration] = useState(null)

    const handleTouch = (e) => {

        if(e.target == document.getElementById('add-promo-body'))
        {
            dispatch({type : 'TOGGLE_MAJORATION_INTERFACE', payload : {status : false,cache : null}})
        }

    }

    const handleSubmit = async e => {

        e.preventDefault()

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        let data = {
            t1 : t1,
            t2 : t2,
            majoration : majoration,
            day : store.settings.majorations.createMemory.id
        }

        majorations.createMajoration(data).then(response => {

            dispatch({type : 'TOGGLE_MAJORATION_INTERFACE', payload : {status : false,cache : null}})

            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

            if(response.status == 200)
            {
                window.location.reload()
            }
            else{
                alert(JSON.stringify(response))
            }

        })

    }

    return (

        <section id="add-promo-body" onClick={handleTouch} className="fixed inset-0 bg-gray-900 bg-opacity-75 z-50 flex items-center justify-center">

            <article className="lg:w-7/12 bg-white p-4 overflow-y-auto h-[400px]">

                <form onSubmit={handleSubmit} name="validationForm">

                {store.loading && <GlobalLoader/>}

                <ToastContainer/>

                    <header className="font-bold text-lg">Ajouter une majoration pour {store.settings.majorations.createMemory.day}</header>

                    <div className="pb-4">
                        <label className="block py-4">Heure 1</label>
                        <input type="time" onChange={e => setT1(e.target.value)} required className="bg-transparent border border-gray-400 p-2 w-full focus:outline-none"  placeholder="Nom du produit"/>
                    </div>

                    <div className="pb-4">
                        <label className="block py-4">Heure 2</label>
                        <input type="time" onChange={e => setT2(e.target.value)} required className="bg-transparent border border-gray-400 p-2 w-full focus:outline-none"  placeholder="Nom du produit"/>
                    </div>

                    <div className="pb-4">
                        <label className="block py-4">Majoration</label>
                        <input type="number"  onChange={e => setMajoration(e.target.value)} required step="0.001" name="color" className="bg-transparent border border-gray-400 p-2 w-full focus:outline-none"  placeholder="Nom du produit"/>
                    </div>

                    <div>
                        <button type="submit" className="bg-[#2e7ba5] p-2 pr-10 text-white">Effectuer</button>
                    </div>
                    
                </form>

            </article>

        </section>

    )

}