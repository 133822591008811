import React, { useContext, useEffect, useState } from "react";

import { ArrowRightIcon, CogIcon, PencilIcon, PlusCircleIcon, TrashIcon } from "@heroicons/react/24/outline";

import useRequest from "../../hooks/useRequest";
import { GET_CONFIGS, GET_LOCOMOTION_CATEGORIES, UPDATE_CONFIGS, UPDATE_LOCOMOTION_CATEGORIES_PRICE } from "../../resources/routes";
import { AppStore } from "../../App";
import GlobalLoader from "../../components/GlobalLoader";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import majorations from "../../services/majoration.service";
import CreateMajoration from "../../components/Majoration/Create";
import Majoration from "../../components/Majoration/Majoration";
import UpdateMajoration from "../../components/Majoration/Update";

export default function Settings({ctx_context})
{

    const {store,dispatch} = useContext(AppStore)

    // const [configs,setConfigs] = useState({
    //     categories : []
    // })

    const [data,setData] = useState({
        categories : {
            clim : {km : null,minute : null, price : null,base_distance : null},
            confort : {km : null,minute : null, price : null,base_distance : null},
            zem : {km : null,minute : null, price : null,base_distance : null},
            vsimple : {km : null,minute : null, price : null,base_distance : null},
            livraison : {km : null,minute : null, price : null,base_distance : null},
            tricycle : {km : null,minute : null, price : null,base_distance : null}
        }
    })

    const [configs,setConfigs] = useState({})

    const {getData,postData} = useRequest()

    const fetch_categories_params = async () => {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        let response = await getData(GET_LOCOMOTION_CATEGORIES)

        if(response)
        {
            if(response.status == 200)
            {
                console.log(response.data.categories)
                // dispatch({type : 'SET_LOCOMOTION_CATEGORIES', payload : response.data.categories})
                setData({
                    ...data, 
                    categories : {
                        clim : {
                            km : response.data.categories[0]?.kilometer_price,
                            minute : response.data.categories[0]?.minute_pricing,
                            price : response.data.categories[0]?.price,
                            base_distance : response.data.categories[0]?.base_distance
                        },
                        confort : {
                            km : response.data.categories[3]?.kilometer_price,
                            minute : response.data.categories[3]?.minute_pricing,
                            price : response.data.categories[3]?.price,
                            base_distance : response.data.categories[3]?.base_distance
                        },
                        zem : {
                            km : response.data.categories[2]?.kilometer_price,
                            minute : response.data.categories[2]?.minute_pricing,
                            price : response.data.categories[2]?.price,
                            base_distance : response.data.categories[2]?.base_distance,
                        },
                        vsimple : {
                            km : response.data.categories[1]?.kilometer_price,
                            minute : response.data.categories[1]?.minute_pricing,
                            price : response.data.categories[1]?.price,
                            base_distance : response.data.categories[1]?.base_distance
                        },
                        livraison : {
                            km : response.data.categories[4]?.kilometer_price,
                            minute : response.data.categories[4]?.minute_pricing,
                            price : response.data.categories[4]?.price,
                            base_distance : response.data.categories[4]?.base_distance
                        },
                        tricycle : {
                            km : response.data.categories[5]?.kilometer_price,
                            minute : response.data.categories[5]?.minute_pricing,
                            price : response.data.categories[5]?.price,
                            base_distance : response.data.categories[5]?.base_distance
                        },
                    }
                })
            }
        }

        dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

    }

    const handleCategoriesValueChange = async e => {

        e.preventDefault()

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        const local_data = {
            clim_km : data.categories.clim.km,
            clim_min : data.categories.clim.minute,
            clim_price : data.categories.clim.price,
            clim_base_distance : data.categories.clim.base_distance,

            confort_km : data.categories.confort.km,
            confort_min : data.categories.confort.minute,
            confort_price : data.categories.confort.price,
            confort_base_distance : data.categories.confort.base_distance,

            zem_km : data.categories.zem.km,
            zem_min : data.categories.zem.minute,
            zem_price : data.categories.zem.price,
            zem_base_distance : data.categories.zem.base_distance,

            vsimple_km : data.categories.vsimple.km,
            vsimple_min : data.categories.vsimple.minute,
            vsimple_price : data.categories.vsimple.price,
            vsimple_base_distance : data.categories.vsimple.base_distance,

            livraison_km : data.categories.livraison.km,
            livraison_min : data.categories.livraison.minute,
            livraison_price : data.categories.livraison.price,
            livraison_base_distance : data.categories.livraison.base_distance,

            tricycle_km : data.categories.tricycle.km,
            tricycle_min : data.categories.tricycle.minute,
            tricycle_price : data.categories.tricycle.price,
            tricycle_base_distance : data.categories.tricycle.base_distance,

        }

        // alert(JSON.stringify(data))

        // Handle the request here

        const response = await postData(local_data,UPDATE_LOCOMOTION_CATEGORIES_PRICE)

        if(response)
        {
            if(response.status == 200)
            {
                await fetch_categories_params().then(() => fetch_configs())
                toast.success('Parametres des categories mis a jours')
                
            }
            else{
                alert(JSON.stringify(response))
                toast.warning('Something went wrong')
            }
        }

        dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

    }

    const fetch_configs =  async () => {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        let response = await getData(GET_CONFIGS)

        if(response)
        {
            if(response.status == 200)
            {
                // alert(JSON.stringify(response.data.configs))
                setConfigs(response.data.configs[0])
                
            }
        }

        dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

    }

    const fetch_majorations = async () => {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        await majorations.getMajorations()
        .then(response => {

            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

            if(response.status == 200)
            {
                // alert(JSON.stringify(response.data.days))
                dispatch({type : 'SET_MAJORATIONS',payload : response.data.days})
            }
            else{
                alert(JSON.stringify(response))
            }

        })
        

    }

    const update_configs = async e => {

        e.preventDefault()

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        let l_data = configs

        // alert(JSON.stringify(l_data))

        const response = await postData(l_data,UPDATE_CONFIGS)

        if(response.status == 200)
        {
            await fetch_categories_params().then(() => fetch_configs())
            toast.success('Parametres des categories mis a jours')
            
        }
        else{
            // alert(JSON.stringify(response))
            toast.warning('Something went wrong')
        }

        dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

    }   

    useEffect(() => {

        fetch_categories_params().then(() => fetch_configs()).then(() => fetch_majorations())

    },[])

    return (

        <section className="w-full">

            <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg mb-14 flex justify-between">
                <div className="">
                    <CogIcon className="w-6 inline-flex text-black"/>
                    <span className="font-bold pl-2 text-black">Paramètres</span>
                </div>
                {/* <div className="">
                    <button className="bg-blue-800 px-4 py-2 text-sm rounded cursor-pointer text-white ml-5 inline-flex items-center justify-center">Ajouter +</button>
                </div> */}
            </header>

            <ToastContainer/>

            {store.loading && <GlobalLoader/>}

            <article className="my-10">

                <header className="font-bold text-xl">
                    Paramétrer les catégories de locomotion
                </header>

                <div className="">

                    <form className="" onSubmit={handleCategoriesValueChange}>

                        <div className="grid md:grid-cols-1 gap-6">

                            <div className="grid md:grid-cols-4 gap-6">

                                <div className="">

                                    <label className="block my-3">Engin Climé (Prix au km)</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={data.categories.clim.km} onChange={e => setData({...data,categories : {...data.categories,clim : { ...data.categories.clim, km : e.target.value }}})} type="text" placeholder=""/>

                                </div>

                                <div className="">

                                    <label className="block my-3">Engin Climé (Prix à la minute)</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={data.categories.clim.minute} onChange={e => setData({...data,categories : {...data.categories,clim : { ...data.categories.clim, minute : e.target.value }}})}  type="text" placeholder=""/>

                                </div>

                                <div className="">

                                    <label className="block my-3">Engin Climé (Prix de base)</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={data.categories.clim.price} onChange={e => setData({...data,categories : {...data.categories,clim : { ...data.categories.clim, price : e.target.value }}})}  type="text" placeholder=""/>

                                </div>

                                <div className="">

                                    <label className="block my-3">Distance de base (km)</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={data.categories.clim.base_distance} onChange={e => setData({...data,categories : {...data.categories,clim : { ...data.categories.clim, base_distance : e.target.value }}})}  type="text" placeholder=""/>

                                </div>

                            </div>

                            <div className="grid md:grid-cols-4 gap-6">

                                <div className="">

                                    <label className="block my-3">Engin Confort (Prix au km)</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={data.categories.confort.km} onChange={e => setData({...data,categories : {...data.categories,confort : { ...data.categories.confort, km : e.target.value }}})}  type="text" placeholder=""/>

                                </div>

                                <div className="">

                                    <label className="block my-3">Engin Confort (Prix à la minute)</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={data.categories.confort.minute} onChange={e => setData({...data,categories : {...data.categories,confort : { ...data.categories.confort, minute : e.target.value }}})}  type="text" placeholder=""/>

                                </div>

                                <div className="">

                                    <label className="block my-3">Engin Confort (Prix de base)</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={data.categories.confort.price} onChange={e => setData({...data,categories : {...data.categories,confort : { ...data.categories.confort, price : e.target.value }}})}  type="text" placeholder=""/>

                                </div>

                                <div className="">

                                    <label className="block my-3">Distance de base (km)</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={data.categories.confort.base_distance} onChange={e => setData({...data,categories : {...data.categories,confort : { ...data.categories.confort, base_distance : e.target.value }}})}  type="text" placeholder=""/>

                                </div>

                            </div>

                            <div className="grid md:grid-cols-4 gap-6">

                                <div className="">

                                    <label className="block my-3">Zem (Prix au km)</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={data.categories.zem.km} onChange={e => setData({...data,categories : {...data.categories,zem : { ...data.categories.zem, km : e.target.value }}})}  type="text" placeholder=""/>

                                </div>

                                <div className="">

                                    <label className="block my-3">Zem (Prix à la minute)</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={data.categories.zem.minute} onChange={e => setData({...data,categories : {...data.categories,zem : { ...data.categories.zem, minute : e.target.value }}})}  type="text" placeholder=""/>

                                </div>

                                <div className="">

                                    <label className="block my-3">Zem (Prix de base)</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={data.categories.zem.price} onChange={e => setData({...data,categories : {...data.categories,zem : { ...data.categories.zem, price : e.target.value }}})}  type="text" placeholder=""/>

                                </div>

                                <div className="">

                                    <label className="block my-3">Distance de base (km)</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={data.categories.zem.base_distance} onChange={e => setData({...data,categories : {...data.categories,zem : { ...data.categories.zem, base_distance : e.target.value }}})}  type="text" placeholder=""/>

                                </div>

                            </div>

                            <div className="grid md:grid-cols-4 gap-6">

                                <div className="">

                                    <label className="block my-3">Vehicule simple (Prix au km)</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={data.categories.vsimple.km} onChange={e => setData({...data,categories : {...data.categories,vsimple : { ...data.categories.vsimple, km : e.target.value }}})}  type="text" placeholder=""/>

                                </div>

                                <div className="">

                                    <label className="block my-3">Vehicule simple (Prix à la minute)</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={data.categories.vsimple.minute} onChange={e => setData({...data,categories : {...data.categories,vsimple : { ...data.categories.vsimple, minute : e.target.value }}})}  type="text" placeholder=""/>

                                </div>

                                <div className="">

                                    <label className="block my-3">Vehicule simple (Prix de base)</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={data.categories.vsimple.price} onChange={e => setData({...data,categories : {...data.categories,vsimple : { ...data.categories.vsimple, price : e.target.value }}})}  type="text" placeholder=""/>

                                </div>

                                <div className="">

                                    <label className="block my-3">Distance de base (km)</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={data.categories.vsimple.base_distance} onChange={e => setData({...data,categories : {...data.categories,vsimple : { ...data.categories.vsimple, base_distance : e.target.value }}})}  type="text" placeholder=""/>

                                </div>

                            </div>

                            <div className="grid md:grid-cols-4 gap-6">

                                <div className="">

                                    <label className="block my-3">Livraison (Prix au km)</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={data.categories.livraison.km} onChange={e => setData({...data,categories : {...data.categories,livraison : { ...data.categories.livraison, km : e.target.value }}})}  type="text" placeholder=""/>

                                </div>

                                <div className="">

                                    <label className="block my-3">Livraison (Prix à la minute)</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={data.categories.livraison.minute} onChange={e => setData({...data,categories : {...data.categories,livraison : { ...data.categories.livraison, minute : e.target.value }}})}  type="text" placeholder=""/>

                                </div>

                                <div className="">

                                    <label className="block my-3">Livraison (Prix de base)</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={data.categories.livraison.price} onChange={e => setData({...data,categories : {...data.categories,livraison : { ...data.categories.livraison, price : e.target.value }}})}  type="text" placeholder=""/>

                                </div>

                                <div className="">

                                    <label className="block my-3">Distance de base (km)</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={data.categories.livraison.base_distance} onChange={e => setData({...data,categories : {...data.categories,livraison : { ...data.categories.livraison, base_distance : e.target.value }}})}  type="text" placeholder=""/>

                                </div>

                            </div>

                            {/* Tricycle */}

                            <div className="grid md:grid-cols-4 gap-6">

                                <div className="">

                                    <label className="block my-3">Tricycle (Prix au km)</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={data.categories.tricycle.km} onChange={e => setData({...data,categories : {...data.categories,tricycle : { ...data.categories.tricycle, km : e.target.value }}})}  type="text" placeholder=""/>

                                </div>

                                <div className="">

                                    <label className="block my-3">Tricycle (Prix à la minute)</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={data.categories.tricycle.minute} onChange={e => setData({...data,categories : {...data.categories,tricycle : { ...data.categories.tricycle, minute : e.target.value }}})}  type="text" placeholder=""/>

                                </div>

                                <div className="">

                                    <label className="block my-3">Tricycle (Prix de base)</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={data.categories.tricycle.price} onChange={e => setData({...data,categories : {...data.categories,tricycle : { ...data.categories.tricycle, price : e.target.value }}})}  type="text" placeholder=""/>

                                </div>

                                <div className="">

                                    <label className="block my-3">Distance de base (km)</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={data.categories.tricycle.base_distance} onChange={e => setData({...data,categories : {...data.categories,tricycle : { ...data.categories.tricycle, base_distance : e.target.value }}})}  type="text" placeholder=""/>

                                </div>

                            </div>

                        </div>

                        

                        <div className="mt-4">
                            <button type="submit" className="bg-[#2e7ba5] p-2 pr-10 text-white rounded">
                                <span>Mettre à jour</span>
                            </button>
                        </div>

                    </form>

                </div>

            </article>

            <article className="my-10">

                <header className="font-bold text-xl">
                    Paramétrer les commissions
                </header>

                <div className="">

                    <form className="" onSubmit={update_configs}>

                        <div className="grid md:grid-cols-2 gap-6">

                            <div className="grid md:grid-cols-1 gap-6">

                                <div className="">

                                    <label className="block my-3">Commission generale</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={configs?.normal_commission} onChange={e => setConfigs({...configs,normal_commission : e.target.value })} type="text" placeholder=""/>

                                </div>

                            </div>

                        </div>

                        <div className="grid md:grid-cols-2 gap-6">

                            <div className="grid md:grid-cols-1 gap-6">

                                <div className="">

                                    <label className="block my-3">Penalite d'annulation (FCFA)</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={configs?.canceling_penalty} onChange={e => setConfigs({...configs,canceling_penalty : e.target.value })} type="text" placeholder=""/>

                                </div>

                            </div>

                        </div>

                        <div className="grid md:grid-cols-2 gap-6">

                            <div className="grid md:grid-cols-1 gap-6">

                                <div className="">

                                    <label className="block my-3">Commission specialisee</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={configs?.spcom} onChange={e => setConfigs({...configs,spcom : e.target.value })} type="text" placeholder=""/>

                                </div>

                            </div>

                        </div>

                        <div className="grid md:grid-cols-2 gap-6">

                            <div className="grid md:grid-cols-1 gap-6">

                                <div className="">

                                    <label className="block my-3">Perimetre de recherche</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={configs?.search_zone} onChange={e => setConfigs({...configs,search_zone : e.target.value })} type="text" placeholder=""/>

                                </div>

                            </div>

                        </div>

                        <div className="mt-4">
                            <button type="submit" className="bg-[#2e7ba5] p-2 pr-10 text-white rounded">
                                <span>Mettre à jour</span>
                            </button>
                        </div>

                    </form>

                </div>

                {/* Commissions on bookings */}

                <div className="">

                    <form className="" onSubmit={update_configs}>

                        <div className="grid md:grid-cols-2 gap-6">

                            <div className="grid md:grid-cols-1 gap-6">

                                <div className="">

                                    <label className="block my-3">Commission sur la location d'appartements meubles (%)</label>
                                    <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={configs?.house_commission} onChange={e => setConfigs({...configs,house_commission : e.target.value })} type="text" placeholder=""/>

                                </div>

                            </div>

                        </div>

                        <div className="mt-4">
                            <button type="submit" className="bg-[#2e7ba5] p-2 pr-10 text-white rounded">
                                <span>Mettre à jour</span>
                            </button>
                        </div>

                    </form>

                </div>

            </article>

            <article className="my-10">

                <header className="font-bold text-xl">
                    Majorations
                </header>

                {store.settings.majorations.createInterfaceState && <CreateMajoration/>}

                {store.settings.majorations.updateInterfaceState && <UpdateMajoration/>}

                <div className="">

                    {
                        store.settings.majorations.list.length > 0 ?

                        <div className="grid grid-cols-1 gao-5">

                            {store.settings.majorations.list.map(day => (

                                <div className="">

                                    <header className="font-bold text-lg mt-10 flex gap-2 items-center">
                                        <span className="inline-block mr-5 capitalize">{day.day}</span>
                                        <button onClick={e => dispatch({type : 'TOGGLE_MAJORATION_INTERFACE',payload : {status : true,cache : day}})} className="bg-blue-700 text-white p-1 inline-flex gap-1 text-xs rounded-xl"><PlusCircleIcon className="w-3"/> <span className="">Ajouter</span></button>
                                    </header>

                                    {
                                        day.majorations.length > 0 ? 

                                        <div className="grid grid-cols-5 gap-5 mt-5">
                                            
                                            {
                                                day.majorations.map(majoration => <Majoration majoration={majoration}/>)
                                            }

                                        </div>

                                        :

                                        <div className="italic text-sm mt-5">Aucune majoration enregistree pour le {day.day}</div>
                                    }

                                </div>

                            ))}

                        </div>

                        : 

                        <div className="">Aucune majoration</div>
                        
                    }

                </div>

            </article>

        </section>

    )

}