import { EyeIcon, PencilIcon, ShoppingBagIcon, TrashIcon } from "@heroicons/react/24/outline";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import AddProduct from "../../components/Product/AddProduct";
import GlobalLoader from "../../components/GlobalLoader";
import Spinner from "../../components/Spinner";
import useRequest from "../../hooks/useRequest";
import { DELETE_PRODUCT, GET_PRODUCTS } from "../../resources/routes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { AppStore } from "../../App";

const AddProductContext = createContext({
    visible : null,
    setVisible : () => {}
})

export default function Products()
{

    const {store,dispatch} = useContext(AppStore)

    const [visible,setVisible] = useState(false)

    const {postData,getData} = useRequest()

    const addProductMemo = useMemo(() => ({visible : visible , setVisible : setVisible}))

    const fetch_products = async () => {

        dispatch({type : 'TOGGLE_PRODUCT_PAGE_LOADER', payload : true})

        let response = await getData(GET_PRODUCTS)

        if(response.status == 200)
        {
            dispatch({type : 'SET_PRODUCTS', payload : response.data.products})
        }

        dispatch({type : 'TOGGLE_PRODUCT_PAGE_LOADER', payload : false})

    }

    const toggleAddBox = () => {

        setVisible(visible ? false : true)

    }

    const delete_product = async (e,id) => {

        e.preventDefault()

        if(window.confirm('Cette action est irréversible, voulez vous poursuivre ?'))
        {

            dispatch({type : 'TOGGLE_PRODUCT_PAGE_LOADER', payload : true})

            let data = {
                product_id : id
            }

            let response = await postData(data,DELETE_PRODUCT)

            if(response.status == 200)
            {
                toast.success('Produit supprimé avec succès')
                setTimeout(() => window.location.reload(), 2000);
            }
            else if(response.status == 404)
            {
                toast.error('Produit introuvable')
            }
            else{
                alert(JSON.stringify(response))
            }

            dispatch({type : 'TOGGLE_PRODUCT_PAGE_LOADER', payload : false})

        }

    }

    useEffect(() => {

        fetch_products()

    },[])

    return (

        <>

            <section className="w-full">

                <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg mb-14 flex items-center justify-between">
                    <div className="">
                        <ShoppingBagIcon className="w-6 inline-flex text-black"/>
                        <span className="font-bold pl-2 text-black">Vos produits</span>
                    </div>
                    <div className="">
                        <button onClick={() => dispatch({type : 'TOGGLE_PRODUCT_ADD_INTERFACE', payload : true})} className="bg-blue-800 px-4 py-2 text-sm rounded cursor-pointer text-white ml-5 inline-flex items-center justify-center">Ajouter</button>
                    </div>
                </header>

                <ToastContainer/>

                {store.products.loading ? <Spinner/> : 

                    <article className="w-full">

                    {
                        store.products.list.length == 0 ?

                        <div>Aucun partenaire en attente</div> : 

                        <table className="w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                #
                                </th>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Nom
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Action
                                </th>
                            </tr>
                            </thead> 
                            <tbody className="divide-y divide-gray-200 bg-white">
                            {store.products.list.map(product => (
                                <tr key={product.id}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {product.id}
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                        {product.name}
                                    </td>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex gap-3">
                                        <Link to={`/produit/${product.id}`}  className="text-blue-600 hover:text-blue-900">
                                            <EyeIcon className="w-6"/>
                                        </Link>
                                        <a href=""  className="text-blue-600 hover:text-blue-900">
                                            <PencilIcon className="w-6"/>
                                        </a>
                                        <a href="" onClick={e => delete_product(e,product.id)} className="text-red-600 hover:text-red-900">
                                            <TrashIcon className="w-6"/>
                                        </a>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                    }

                    </article>                
                
                }

                {
                    store.products.addInterfaceVisible && <AddProduct/>
                }

            </section>

        </>

    )

}