import React from "react";
import "../resources/spinner.css"

export default function Spinner()
{

    return (

        <section>

            <span class="loader"></span>

        </section>

    )

}