import React from "react";

import {
    NewspaperIcon,
    WifiIcon,
    SignalSlashIcon
  } from '@heroicons/react/24/outline'

export default function Connectivity()
{

    return (

        <section className="fixed inset-0 bg-[#004665] bg-opacity-90 z-50 flex items-center justify-center">

            <article className="flex items-center justify-center flex-col">

                <div className="">
                    <SignalSlashIcon className=" w-20 text-white"/>
                </div>

                <div className="text-white">
                    Vous avez perdu l'accès à internet
                </div>

            </article>

        </section>

    )

}