import { useContext, useState } from "react"
import { AppStore } from "../../App"
import GlobalLoader from "../GlobalLoader"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useRequest from "../../hooks/useRequest";
import { CREATE_DISCOUNT } from "../../resources/routes";


export default function CreatePromo(){

    const {store,dispatch} = useContext(AppStore)

    const [code,setCode] = useState(null)
    const [percentage,setPercentage] = useState(null)

    const {postData} = useRequest()

    const handleTouch = (e) => {

        if(e.target == document.getElementById('add-promo-body'))
        {
            dispatch({type : 'TOGGLE_ADD_PROMO_INTERFACE', payload : false})
        }

    }

    function generateRandomCode(length) {
        const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            result += charset[randomIndex];
        }
        return result;
    }

    const generate_code = e => {

        e.preventDefault()

        setCode(generateRandomCode(8))

    }

    const handleSubmit = async e => {

        e.preventDefault()

        if(!code && !percentage)
        {
            toast.warning('Veuillez fournir les donnees')
        }
        else{

            dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

            let data  = {
                code : code,
                percentage : percentage
            }

            let response = await postData(data,CREATE_DISCOUNT)

            if(response.status == 200)
            {

                window.location.reload()

                toast.success('Code promo ajouté avec succès')

                dispatch({type : 'TOGGLE_ADD_PROMO_INTERFACE', payload : false})

            }
            else{
                alert(JSON.stringify(response))
            }

            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

        }

    }

    return (

        <section id="add-promo-body" onClick={handleTouch} className="fixed inset-0 bg-gray-900 bg-opacity-75 z-50 flex items-center justify-center">

            <article className="lg:w-7/12 bg-white p-4 overflow-y-auto h-[400px]">

                <form onSubmit={handleSubmit} name="validationForm">

                {store.loading && <GlobalLoader/>}

                <ToastContainer/>

                    <header className="font-bold text-lg">Ajouter un produit</header>

                    <div className="pb-4">
                        <label className="block py-4">Code</label>
                        <a onClick={generate_code} className="text-blue-700 underline pb-4 block text-sm cursor-pointer">Generer le code</a>
                        <input type="text" name="color" value={code} onChange={e => setCode(e.target.value)} className="bg-transparent border border-gray-400 p-3 w-full focus:outline-none"  placeholder="Code"/>
                    </div>

                    <div className="pb-4">
                        <label className="block py-4">Pourcentage</label>
                        <input type="text" name="color" onChange={e => setPercentage(e.target.value)} className="bg-transparent border border-gray-400 p-3 w-full focus:outline-none"  placeholder="Pourcentage"/>
                    </div>

                    <div>
                        <button type="submit" className="bg-[#2e7ba5] p-2 pr-10 text-white">Effectuer</button>
                    </div>
                    
                </form>

            </article>

        </section>

    )

}