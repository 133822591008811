import { ArrowRightIcon, CogIcon, PencilIcon, PlusCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import majorations from "../../services/majoration.service";
import { useContext } from "react";
import { AppStore } from "../../App";
import GlobalLoader from "../GlobalLoader";

export default function Majoration({majoration}){

    const {store,dispatch} = useContext(AppStore)

    const mid = majoration.id

    const deleteMajoration = async () => {

        let data = {
            id : mid
        }

        if(window.confirm('Voulez vous vraiment supprimer cette majoration ?'))
        {

            dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

            // Process it here

            majorations.deleteMajoration(data)
            .then(response => {

                dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

                if(response.status == 200)
                {
                    return window.location.reload()
                }
                else{
                    // Throw : response stringified
                    alert('Something went wrong')
                }

            })

        }

    }

    return (

        <div className="shadow shadow-gray-600 p-2 rounded-md">

            {store.loading && <GlobalLoader/>}

            <header className="font-extrabold">{majoration.t1} - {majoration.t2}</header>
            <p className="mt-3">{majoration.majoration} %</p>

            <div className="grid grid-cols-2 gap-3 mt-3">
                <button onClick={e => dispatch({type : 'TOGGLE_MAJORATION_UPDATE_INTERFACE',payload : {status : true, cache : majoration}})} type="submit" className="bg-[#2e7ba5] p-2 text-white rounded text-sm inline-flex gap-2">
                    <PencilIcon className="w-3"/>
                    <span>Modifier</span>
                </button>
                <button onClick={deleteMajoration} type="submit" className="bg-red-600 p-2 text-white rounded text-sm inline-flex gap-2">
                    <TrashIcon className="w-3"/>
                    <span>Supprimer</span>
                </button>
            </div>

        </div>

    )

}