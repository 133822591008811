import React, { useContext, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import GlobalLoader from "../components/GlobalLoader";

export default function Login({auth_tool})
{

    const {login,logout, setLogin} = useContext(auth_tool)

    const [username,setUsername] = useState()

    const [password,setPassword] = useState()

    const [loading,setLoading] = useState(false)

    const handleSubmit = async (e) => {

        e.preventDefault()

        setLoading(true)

        let response = await login(username,password)

        // alert(JSON.stringify(response))

        setLoading(false)

        if(response.status == 403)
        {
            toast.warning(response.data.message)
        }
        else if(response.status == 200)
        {

            // Call set Login here

            // alert(response.data.access_token)

            setLogin(response.data,true)

        }

        // Here set app as logged in

        // First of all, alert the data and call setLogin function

    }

    return (

        <section className="min-h-[100vh] flex items-center justify-center">
            
            <form onSubmit={handleSubmit} className="bg-[#004165] text-white lg:w-5/12 lg:p-4">

                {loading && <GlobalLoader/>}

                <div className="pb-4">
                    <label htmlFor="username" className="block py-4">Votre numéro</label>
                    <input type="text" className="bg-[#1d516d] p-3 w-full" id="username" onInput={(e) => setUsername(e.target.value)} placeholder="Votre email"/>
                </div>

                <div className="pb-4">
                    <label htmlFor="password" className="block py-4">Mot de passe</label>
                    <input type="password" id="password" className="bg-[#1d516d] p-3 w-full" onInput={(e) => setPassword(e.target.value)}  placeholder="Votre mot de passe"/>
                </div>

                <div>
                    <button type="submit" className="bg-[#2e7ba5] p-3">Connexion</button>
                </div>

            </form>

            <ToastContainer/>

        </section>

    )

}