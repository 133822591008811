import { getData, postData } from "../lib/request";
import { ACTIVATE_FURNISHED_FLAT, ACTIVATE_RENTAL_PROPERTY, GET_TAXABLE_HOUSES, GET_UNACTIVATED_FLATS, GET_UNACTIVATED_RENTAL_PROPERTIES, PAY_TAXE } from "../resources/routes";


export default class house {

    static async getHouses()
    {

        let response = await getData(GET_TAXABLE_HOUSES)

        return response

    }

    static async payTaxe(data)
    {

        let response = await postData({},PAY_TAXE + '/' + data.house_id)

        return response

    }

    static async getUnactivatedFurnishedFlats()
    {

        let response = await getData(GET_UNACTIVATED_FLATS)

        return response

    }

    static async activateFlat(data)
    {

        let response = await postData(data,ACTIVATE_FURNISHED_FLAT)

        return response

    }

    static async getUnactivatedRentalProperties()
    {

        let response = await getData(GET_UNACTIVATED_RENTAL_PROPERTIES)

        return response

    }

    static async activateRentalProperty(data)
    {

        let response = await postData(data,ACTIVATE_RENTAL_PROPERTY)

        return response

    }

}