import { PencilIcon, TagIcon, TrashIcon } from "@heroicons/react/24/outline";
import { GET_DISCOUNTS } from "../../resources/routes";
import { useContext, useEffect } from "react";
import { AppStore } from "../../App";
import useRequest from "../../hooks/useRequest";
import GlobalLoader from "../../components/GlobalLoader";
import CreatePromo from "../../components/Promo/CreatePromo";

function Discount({data})
{

    return (

        <div className="shadow shadow-gray-500 p-4">

            <div className="">
                <div className="">
                    <b className="">Code : </b> {data.code}
                </div>
                <div className="my-4">
                    <b className="">Reduction : </b> {data.percentage}%
                </div>
            </div>

            <div className="flex gap-5">

                <button className="">
                    <TrashIcon className="w-5 text-red-700"/>
                </button>

                <button className="">
                    <PencilIcon className="w-5 text-blue-700"/>
                </button>
                
            </div>

        </div>

    )

}

export default function Promo()
{

    const {store,dispatch} = useContext(AppStore)

    const {getData,postData} = useRequest()

    const fetch_discounts = async () => {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        let response = await getData(GET_DISCOUNTS)

        if(response.status == 200)
        {
            // alert(JSON.stringify(response.data.discounts))
            dispatch({type : 'SET_DISCOUNTS',payload : response.data.discounts})
            
        }

        dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

    }

    useEffect(() => {

        fetch_discounts()

    },[])

    return (

        <section className="w-full">

            {store.loading && <GlobalLoader/>}

            <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg mb-14 flex justify-between">
                <div className="">
                    <TagIcon className="w-6 inline-flex text-black"/>
                    <span className="font-bold pl-2 text-black">Codes promos</span>
                </div>
                <div className="">
                    <button onClick={() => dispatch({type : 'TOGGLE_ADD_PROMO_INTERFACE', payload : true})} className="bg-blue-800 px-4 py-2 text-sm rounded cursor-pointer text-white ml-5 inline-flex items-center justify-center font-bold">Ajouter +</button>
                </div>
            </header>

            {store.promo.addInterfaceVisible && <CreatePromo/>}

            <article className="">

                {
                    store.promo.list.length > 0 ?

                    <div className="grid md:grid-cols-5 gap-5">

                        {store.promo.list.map(discount => <Discount data={discount}/>)}

                    </div>

                    :

                    <div className="">
                        Aucun code promo pour le moment
                    </div>

                }

            </article>

        </section>

    )

}