import { getData, postData } from "../lib/request";
import { CREATE_CATEGORY, DELETE_CATEGORY, GET_CATEGORIES, UPDATE_CATEGORY } from "../resources/routes";


export default class categories {

    static async getCategories()
    {

        let response = await getData(GET_CATEGORIES)

        return response

    }

    static async createCategory(data)
    {

        let response = await postData(data,CREATE_CATEGORY)

        return response

    }

    static async updateCategory(data)
    {

        let response = await postData(data,UPDATE_CATEGORY)

        return response

    }

    static async deleteCategory(data)
    {

        let response = await postData({},DELETE_CATEGORY + '/' + data.id)

        return response

    }

}