import logo from './logo.svg';
import './App.css';
import AuthPorvider from './services/AuthProvider.service';
import { createContext, useReducer } from 'react';
import { reduce, initalState } from './store/store';

export const AppStore = createContext()


function App() {

  const [store,dispatch] = useReducer(reduce,initalState)

  return (
    
    <AppStore.Provider value={{store : store, dispatch : dispatch}}>
      <AuthPorvider></AuthPorvider>
    </AppStore.Provider>

  );
}

export default App;
