import React, { useContext } from "react"
import { AppStore } from "../../App"

function PaginationButton({data,index})
{

    const {store,dispatch} = useContext(AppStore)

    return (

        <React.Fragment>

            <button onClick={e => dispatch({type : 'SET_ERRAND_LIST_INDEX',payload : index})} className={index == store.errands.listIndex ? "bg-gray-400 inline-flex items-center justify-center w-8 h-8 text-white text-sm font-bold cursor-default" : "bg-blue-600 inline-flex items-center justify-center w-8 h-8 text-white text-sm font-bold"}>{index}</button>

        </React.Fragment>

    )

}

export default function Pagination({data})
{

    return (

        <div className="flex gap-2">

            {
                data.map((chunk,index) => <PaginationButton data={chunk} index={index}/>)
            }

        </div>

    )

}