import { useContext, useEffect, useState } from "react"
import { AppStore } from "../../App"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CheckBadgeIcon, ShoppingBagIcon, TruckIcon } from "@heroicons/react/24/outline";
import CarService from "./car.service";
import GlobalLoader from "../../components/GlobalLoader";
import Spinner from "../../components/Spinner";
import ImageZoom from "../../components/ImageZoom";

export default function PendingCars()
{

    const {store,dispatch} = useContext(AppStore)

    const [zoomActive,setZoomActive] = useState(false)

    const [link,setLink] = useState(null)

    const fetchPendingCars = async () => {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        await CarService.getPendingCars()
        .then(res => {

            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})
            
            dispatch({type : 'SET_PENDING_CARS', payload : res.data.cars})

        })

    }

    const setZoom = (e,link) => {

        e.preventDefault()

        dispatch({type : 'SET_ZOOM',payload : true})

        setLink(link)

    }

    const setPendingCar = async (e,id) => {
        
        e.preventDefault()

        let data = {
            id : id
        }

        let response = await CarService.setPendingCar(data)

        if(response.status == 200)
        {
            toast.success('Le véhicule est maintenant en attente')
            fetchPendingCars()
        }

    }

    useEffect(() => {

        fetchPendingCars()  

    },[])

    return (

        <section className="w-full">

                <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg mb-14 flex items-center justify-between">
                    <div className="">
                        <TruckIcon className="w-6 inline-flex text-black"/>
                        <span className="font-bold pl-2 text-black">Véhicules en attente</span>
                    </div>
                </header>

                <ToastContainer/>

                {store.loading ? <Spinner/> : 

                    <article className="w-full">

                        {
                            store.pendingCars.length == 0 ?

                            <div>Aucun véhicules en attente</div> : 

                            <table className="w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    #
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Utilisateur
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Marque
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Image avant
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Image arriere
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Action
                                    </th>
                                </tr>
                                </thead> 
                                <tbody className="divide-y divide-gray-200 bg-white">
                                {store.pendingCars.map(car => (
                                    <tr key={car?.id}>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                            {car?.id}
                                        </td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                            {car?.owner?.c_name}
                                        </td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                            {car?.brand}
                                        </td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                            <a onClick={e => setZoom(e,`${car?.front_pic}`)}  className="text-blue-600 hover:text-blue-900 cursor-pointer">
                                                Voir l'image
                                            </a>
                                        </td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                            <a onClick={e => setZoom(e,`${car?.back_pic}`)} className="text-blue-600 hover:text-blue-900 cursor-pointer">
                                                Voir l'image
                                            </a>
                                        </td>
                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex gap-3">
                                            <a onClick={e => setPendingCar(e,car?.id)}  className="text-blue-600 hover:text-blue-900">
                                                <CheckBadgeIcon className="w-6"/>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>

                        }

                        {store.userDetails.zoomActive && <ImageZoom link={link}/>}

                    </article>                

                }
            
        </section>

    )

}