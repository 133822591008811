import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import useRequest from "../../hooks/useRequest";
import { GET_PUBS, GET_USERS, QUEUED_PARTNERS } from "../../resources/routes";
import { AuthContext } from "../../services/AuthProvider.service";
import useRole from "../../hooks/useRole";
import Activation from "../../components/Activation";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PubMember from "../../components/PubMember";
import AddPub from "../../components/AddPub";
import Spinner from "../../components/Spinner";
import { AppStore } from "../../App";
import { Link } from "react-router-dom";
import { EyeDropperIcon, EyeIcon } from "@heroicons/react/24/outline";
import { userService } from "../../services/user.service";
import GlobalLoader from "../../components/GlobalLoader";

const SettingContext = createContext({
    visible : false,
    setVisible : () => {}
})

const AddvContext = createContext({
    addv : false,
    setAddv : () => {}
})

export default function Users({ctx_memo}){

    const {store,dispatch} = useContext(AppStore)

    const {postData,getData} = useRequest()

    const [attrib,setAttrib] = useState(null)

    const [user,setUser] = useState(null)

    const [visible,setVisible] = useState(false)

    const { setRole } = useRole()

    // const {login,logout,isLogin} = useContext(ctx_memo)

    const [loading, setLoading] = useState(true)

    const activationMemo = useMemo(() => ({visible:visible,setVisible:setVisible}))

    const [spinning,setSpinning] = useState(true)

    const callActivation = (e,user,role) => {

        e.preventDefault()

        setUser(user)

        setAttrib(role)

        setVisible(true)

    }

    const fetch_users = async () => {

        let response = await getData(GET_USERS)

        // alert(JSON.stringify(response))

            if(response.status == 401)
            {
                // logout()
            }

            setSpinning(false)

            // alert(JSON.stringify(response.data.users))

            dispatch({type : 'SET_USERS',payload : response.data.users})

            dispatch({type : 'SET_USERS_MEMORY',payload : response.data.users})

    }

    const filterByRole = async e => {

        let data = {
            role : e.target.value
        }

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        userService.filterByRole(data)
        .then(response => {

            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

            if(response.status == 200)
            {
                dispatch({type : 'SET_USERS',payload : response.data.users})
            }
            else{
                alert('Something gone wrong while filtering users')
            }

        })

    }

    const getRoles = async () => {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        userService.getRoles()
        .then(response => {

            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

            if(response.status == 200)
            {
                dispatch({type : 'SET_ROLES',payload : response.data.roles})
            }
            else{
                alert('Something went wrong while fetching roles')
            }

        })

    }

    const filterByName = async e => {

        let name = e.target.value

        let users = store.users

        console.log(name)

        if(name != '')
        {
            let regex = new RegExp(name)
            dispatch({type : 'SET_USERS',payload : users.filter(user => user.c_name.match(regex))})
        }
        else{
            dispatch({type : 'SET_USERS',payload : store.usersMemory})
        }

    }

    useEffect(() => {

        const fetch_queued_partners =  async () => {

            let response = await getData(QUEUED_PARTNERS)

            if(response.status == 401)
            {
                // logout()
            }

            setLoading(false)

            // alert(JSON.stringify(response.data.users))

            dispatch({type : 'SET_PARTNERS',payload : response.data.users})

        }

        fetch_queued_partners()

        fetch_users()

        getRoles()

    },[])

    return (

        <React.Fragment>

            <section className="w-full">

            <ToastContainer/>

                {store.loading && <GlobalLoader/>}

                <header className="font-bold text-lg">Utilisateurs</header>

                {
                    spinning ? 

                    <Spinner/> : 

                    <article className="w-full">

                    <section className="">

                        <article className="flex items-center gap-5">
                            <select className="block my-5 border border-gray-600 p-3 bg-transparent" onChange={filterByRole}>
                                <option>Trier par role</option>
                                <option value='tout'>Tout</option>
                                {store.roles.map(role => <option value={role.name}>{role.name}</option>)}
                            </select>
                            <input onChange={filterByName} className="border border-gray-400 p-2 h-10 block w-5/12" placeholder="Filtrer par conducteur"/>
                        </article>

                        <table className="w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Nom
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Email
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                OPT
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Role
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Action
                                </th>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                            {store.users?.map(user => (
                                <tr key={user.email}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {user.c_name}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.phone}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.validation_code}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <span className="bg-blue-800 text-white px-4 rounded-lg">{setRole(user.role)}</span>
                                    </td>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6">
                                        <Link to={`/user/${user.id}`}>
                                            <EyeIcon className="w-5"/>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                    </section>

                    </article>

                }

                <header className="font-bold text-lg pt-10">Partenaires en attente d'activation</header>

                {
                    loading ? 

                    <Spinner/> : 

                    <article className="w-full">

                        {
                            visible && 
                            <SettingContext.Provider value={activationMemo}>
                                <Activation user={user} role={attrib} visibility={SettingContext}/>
                            </SettingContext.Provider>
                        }

                        {
                            store.partners.length == 0 ?

                            <div>Aucun partenaire en attente</div> : 

                            <table className="w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Nom
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Email
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Role
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Action
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                {store.partners.map(partner => (
                                    <tr key={partner.email}>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                            {partner.c_name}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{partner.email}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <span className="bg-blue-800 text-white px-4 rounded-lg">{setRole(partner.role)}</span>
                                        </td>
                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6">
                                            <a href="" onClick={(e) => callActivation(e,partner.id,partner.role)} className="text-indigo-600 hover:text-indigo-900">
                                            Activer<span className="sr-only"></span>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>

                        }

                    </article>

                }

            </section>

        </React.Fragment>        

    )

}