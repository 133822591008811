/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useEffect } from 'react'
import { Fragment, useContext, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Bars3BottomLeftIcon,
  CogIcon,
  HomeIcon,
  PhotoIcon,
  PlusIcon,
  RectangleStackIcon,
  Squares2X2Icon,
  UserGroupIcon,
  XMarkIcon,
  UserCircleIcon,
  BookOpenIcon,
  LinkIcon,
  ArrowPathIcon,
  MapIcon,
  QuestionMarkCircleIcon,
  BriefcaseIcon,
  BanknotesIcon,
  ShoppingBagIcon,
  ChartPieIcon,
  TagIcon,
  ListBulletIcon,
  CurrencyDollarIcon,
  TruckIcon
} from '@heroicons/react/24/outline'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'

import logo from "../resources/logo.png"
import { BellAlertIcon, BuildingOffice2Icon, WifiIcon } from '@heroicons/react/16/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Frame({children,auth_context}) {

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const {login,logout, setLogin} = useContext(auth_context)

  // const [current, setCurrent] = useState(true)

  // var sidebarNavigation = [
  //   { name: 'Dasboard', href: '/', icon: HomeIcon, current: true },
  //   { name: 'Utilisateurs', href: '/users', icon: BookOpenIcon, current: false },
  //   { name: 'Publicités', href: '/pubs', icon: UserCircleIcon, current: false },
  //   { name: 'Paramètres', href: '#', icon: CogIcon, current: false },
  // ]

  const [sidebarNavigation,setSidebarNavigation] = useState([
    { name: 'Dasboard', href: '/', icon: HomeIcon, current: true },
    { name: 'Notifications', href: '/notifications', icon: BellAlertIcon, current: false },
    { name: 'Activation maisons', href: '/maisons-en-attente-d-activation', icon: BuildingOffice2Icon, current: false },
    { name: 'Utilisateurs en ligne', href: '/live-users', icon: WifiIcon, current: false },
    { name: 'Categories', href: '/categories', icon: ListBulletIcon, current: false },
    { name: 'Promo', href: '/promotion', icon: TagIcon, current: false },
    { name: 'Utilisateurs', href: '/users', icon: UserCircleIcon, current: false },
    { name: 'Publicités', href: '/pubs', icon: LinkIcon, current: false },
    { name: 'Courses', href: '/courses', icon: MapIcon, current: false },
    { name: 'Réservations', href: '/réservations', icon: BookOpenIcon, current: false },
    { name: 'Véhicules en attente', href: '/pending-cars', icon: TruckIcon, current: false },
    { name: 'produits', href: '/produits', icon: ShoppingBagIcon, current: false },
    { name: 'Transactions', href: '/transactions', icon: BanknotesIcon, current: false },
    { name: 'Impots', href: '/impots', icon: CurrencyDollarIcon, current: false },
    { name: 'Comptabilite', href: '/comptabilite', icon: ChartPieIcon, current: false },
    { name: 'Paramètres', href: '/paramètres', icon: CogIcon, current: false },
  ])

  const userNavigation = [
    { name: 'Votre profile', href: '#', action : () => {} },
    { name: 'Déconnexion', href: '#' ,action : logout},
  ]

  const toggle_current = (current) => {
    setSidebarNavigation((prevSidebarNavigation) =>
      prevSidebarNavigation.map((item) => ({
        ...item,
        current: item.href === current.href,
      }))
    );
  };

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full overflow-hidden">
        ```
      */}
      <div className="flex h-full">
        {/* Narrow sidebar */}
        <div className="hidden w-2/12 overflow-y-auto bg-[#004665] md:block min-h-[100vh]">
          <div className="flex w-full flex-col items-center py-6">
            <div className="flex flex-shrink-0 items-center">
              <img
                className=" h-20 w-auto"
                src={logo}
                alt="Your Company"
              />
            </div>
            <div className="mt-6 w-full flex-1 space-y-1 px-2">
              {sidebarNavigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  onClick={() => toggle_current(item)}
                  className={classNames(
                    item.current ? 'bg-[#1c566e] text-white' : 'text-indigo-100 hover:bg-[#1c566e] hover:text-white',
                    'group flex w-full flex-row items-center rounded-md p-3 text-xs font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  <item.icon
                    className={classNames(
                      item.current ? 'text-white' : 'text-indigo-300 group-hover:text-white',
                      'h-6 w-6'
                    )}
                    aria-hidden="true"
                  />
                  <span className="pl-3">{item.name}</span>
                </Link>
              ))}
            </div>
          </div>
        </div>

        {/* Mobile menu */}
        <Transition.Root show={mobileMenuOpen} as={Fragment}>
          <Dialog as="div" className="relative z-20 md:hidden" onClose={setMobileMenuOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-[#004665] pb-4 pt-5">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute right-0 top-1 -mr-14 p-1">
                      <button
                        type="button"
                        className="flex h-12 w-12 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        <span className="sr-only">Close sidebar</span>
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-16 w-auto"
                      src={logo}
                      alt="Your Company"
                    />
                  </div>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto px-2">
                    <nav className="flex h-full flex-col">
                      <div className="space-y-1">
                        {sidebarNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? 'bg-[#1c566e] text-white'
                                : 'text-indigo-100 hover:bg-[#1c566e] hover:text-white',
                              'group flex items-center rounded-md py-2 px-3 text-sm font-medium'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            <item.icon
                              className={classNames(
                                item.current ? 'text-white' : 'text-indigo-300 group-hover:text-white',
                                'mr-3 h-6 w-6'
                              )}
                              aria-hidden="true"
                            />
                            <span>{item.name}</span>
                          </a>
                        ))}
                      </div>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Content area */}
        <div className="flex flex-1 flex-col overflow-hidden">
          <header className="w-full">
            <div className="relative z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white shadow-sm">
              <button
                type="button"
                className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="flex flex-1 justify-between px-4 sm:px-6">
                <div className="flex flex-1">
                  <form className="flex w-full md:ml-0" action="#" method="GET">
                    <label htmlFor="search-field" className="sr-only">
                      Search all files
                    </label>
                    <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                        <MagnifyingGlassIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                      </div>
                      <input
                        name="search-field"
                        id="search-field"
                        className="h-full w-full border-0 py-2 pl-8 pr-3 text-gray-900 focus:outline-none focus:ring-0 focus:placeholder:text-gray-400 sm:text-sm"
                        placeholder="Search"
                        type="search"
                      />
                    </div>
                  </form>
                </div>
                <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative flex-shrink-0">
                    <div>
                      <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <span className="sr-only">Open user menu</span>
                        {/* <img
                          className="h-8 w-8 rounded-full"
                          src={}
                          alt=""
                        /> */}
                        <UserCircleIcon className='w-6'/>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                                href={item.href}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700'
                                )} onClick={() => item.action()}
                              >
                                {item.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>

                  {/* <button
                    type="button"
                    className="flex items-center justify-center rounded-full bg-indigo-600 p-1 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <PlusIcon className="h-6 w-6" aria-hidden="true" />
                    <span className="sr-only">Add file</span>
                  </button> */}
                </div>
              </div>
            </div>
          </header>

          {/* Main content */}
          <div className="flex w-full items-stretch overflow-hidden">

            <main className="overflow-y-auto px-4 py-4 w-full">
              {/* Primary column */}
              <section aria-labelledby="primary-heading" className="flex h-full">
                {children}
              </section>
            </main>

            
          </div>
        </div>
      </div>
    </>
  )
}
