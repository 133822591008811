import React from "react";
import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import Frame from "./Frame";
import { AuthContext } from "../services/AuthProvider.service";

export default function Layout({children,ctx_memo}){

    return (

        <React.Fragment>

            <AuthContext.Provider value={ctx_memo}>
                <Frame children={<Outlet/>} auth_context={AuthContext}/>
            </AuthContext.Provider>

        </React.Fragment>

    )

}