import React from "react";

const API_BASE='https://agbon.tech/api/auth/'

export const SITE_URL = 'https://agbon.tech/'

export const GOOGLE_API_KEY='AIzaSyCah1hWP5KnXiiiTm68b_fBwvws9ertlls'

// const API_BASE='http://localhost:8000/api/auth/'

export default API_BASE