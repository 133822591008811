import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { AppStore } from "../../App"
import { ListBulletIcon, MapIcon, PauseCircleIcon } from "@heroicons/react/24/outline"
import errands from "../../services/errands"
import GlobalLoader from "../../components/GlobalLoader"
import polyline from "@mapbox/polyline"
import { GoogleMap, LoadScript, Polyline } from '@react-google-maps/api';

function Pause({data})
{

    return (

        <div className="shadow shadow-gray-500 p-4">

            <div className="my-5">
                <b>Longitude : </b> <span>{data.longitude}</span>
            </div>

            <div className="my-5">
                <b>Latitude : </b> <span>{data.latitude}</span>
            </div>

            <div className="my-5">
                <b className="font-bold text-green-600">Duree : </b> <span>{data.idle_count} min</span>
            </div>

        </div>

    )

}


export default function ErrandDetails()
{

    const {store,dispatch} = useContext(AppStore)

    const [path,setPath] = useState(null)

    const params = useParams()

    const errand_id = params.id

    const get_errand = () => {

        // Dispatch loader

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        errands.getErrand(errand_id)
        .then(response => {

            // alert(response)

            if(response.status == 200)
            {
                dispatch({type : 'SET_SINGLE_VIEW',payload : response.data.errand})
                setPath(response.data.errand.path)
                dispatch({type : 'TOGGLE_APP_LOADING', payload : false})
            }
            else{
                // Throw something went wrong
                alert('Something went wrong')
                dispatch({type : 'TOGGLE_APP_LOADING', payload : false})
            }

        })

    }

    const mapContainerStyle = {
    height: "500px",
    width: "100%"
    };
    
    const center = {
    lat: 37.7749, // Center of the map
    lng: -122.4194
    };

    const pathCoordinates = [
        { lat: 37.772, lng: -122.214 },
        { lat: 37.768, lng: -122.295 },
        { lat: 37.789, lng: -122.411 },
        { lat: 37.774, lng: -122.404 }
      ];

    useEffect(() => {

        get_errand()

    },[])

    return (

        <section className="w-full">

            <article className="">

                {
                    store.loading ? <GlobalLoader/> : 

                    <section>

                        <section className="">

                        <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg flex items-center">
                            <ListBulletIcon className="w-6 inline-flex text-black"/>
                            <span className="font-bold pl-2 text-black">Informations generales de la course {store.errands.singleViewData.id}</span>
                        </header>

                        <div className="my-5">
                            <b>Client : </b> <span>{store.errands.singleViewData?.theclient?.c_name}</span>
                        </div>

                        <div className="my-5">
                            <b>Conducteur : </b> <span>{store.errands.singleViewData?.thedriver?.c_name}</span>
                        </div>

                        <div className="my-5">
                            <b>Distance totale : </b> <span>{store.errands.singleViewData?.total_distance} Km</span>
                        </div>

                        <div className="my-5">
                            <b>Prix a l'estimation : </b> <span>{store.errands.singleViewData?.total_price} <span className="font-bold text-sm">FCFA</span></span>
                        </div>

                        <div className="my-5">
                            <b>Prix final : </b> <span>{store.errands.singleViewData?.total_price} <span className="font-bold text-sm">FCFA</span></span>
                        </div>

                        </section>

                        <article className="">

                            <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg flex items-center">
                                <PauseCircleIcon className="w-6 inline-flex text-black"/>
                                <span className="font-bold pl-2 text-black">Pauses pendant la course {store.errands.singleViewData?.pauses?.length}</span>
                            </header>

                            <div className="my-5 grid grid-cols-4 gap-5">
                                
                                {
                                    store.errands.singleViewData?.pauses?.map(pause => <Pause data={pause}/>)
                                }

                            </div>

                        </article>

                        <article className="">

                            <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg flex items-center">
                                <MapIcon className="w-6 inline-flex text-black"/>
                                <span className="font-bold pl-2 text-black">Trajet</span>
                            </header>

                            <div className="w-full overflow-auto my-10">

                                {/* {JSON.stringify(store.errands.singleViewData?.path)} */}

                                {/* {JSON.stringify(polyline.decode(path).map(([lat, lng]) => ({ lat, lng })))} */}

                                {
                                    path && 
                                    <LoadScript googleMapsApiKey="AIzaSyCah1hWP5KnXiiiTm68b_fBwvws9ertlls">
                                        <GoogleMap
                                            mapContainerStyle={mapContainerStyle}
                                            zoom={10}
                                            center={{
                                                lat : polyline.decode(path).map(([lat, lng]) => ({ lat, lng }))[0].lat,
                                                lng : polyline.decode(path).map(([lat, lng]) => ({ lat, lng }))[0].lng
                                            }}
                                        >
                                            <Polyline
                                            path={polyline.decode(path).map(([lat, lng]) => ({ lat, lng }))}
                                            options={{
                                                strokeColor: "#FF0000",
                                                strokeOpacity: 1.0,
                                                strokeWeight: 2,
                                            }}
                                            />
                                        </GoogleMap>
                                    </LoadScript>

                                }

                            </div>

                        </article>

                    </section>

                }

            </article>

        </section>

    )

}