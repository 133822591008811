import { useContext } from "react"
import { AppStore } from "../../App"


export default function TaxePaymentHistory()
{

    const {store,dispatch} = useContext(AppStore)

    const handleTouch = (e) => {

        if(e.target == document.getElementById('add-product-body'))
        {
            dispatch({type : 'TOGGLE_HISTORY_INTERFACE', payload : false})
        }

    }


    return (

        <section id="add-product-body" onClick={handleTouch} className="fixed inset-0 bg-gray-900 bg-opacity-75 z-50 flex items-center justify-center">

            <article className="lg:w-7/12 bg-white p-4 overflow-y-auto h-[400px]">


                {/* Show the payments history here */}

                <table className="w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        #
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Montant
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Date
                        </th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                    {store.taxes.history.map((line,index) => (
                        <tr key={line.id}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {index + 1}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{line.amount}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{line.created_at}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>

            </article>

        </section>

    )

}