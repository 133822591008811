import { CheckBadgeIcon, MapIcon } from "@heroicons/react/24/outline";
import GlobalLoader from "../../components/GlobalLoader";
import { useContext, useEffect, useRef, useState } from "react";
import { AppStore } from "../../App";
import house from "../../services/house.service";
import { toast } from "react-toastify";


export default function Houses()
{

    const {store,dispatch} = useContext(AppStore)

    const callExecuted = useRef(false)

    const [flats,setFlats] = useState([])

    const [rps,setRps] = useState([])

    const getUnactivatedFurnishedFlats = async () => {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        let response = await house.getUnactivatedFurnishedFlats()

        // Set the unactivated furnished flats in the application

        if(response)
        {

            if(response.status == 200)
            {
                setFlats(response.data.flats)
            }

        }

        // Switch of the loader

        dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

    }

    const activateFlat = async (e,flat) => {

        e.preventDefault()

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        let response = await house.activateFlat({
            id : flat.id
        })

        if(response)
        {
            if(response.status)
            {
                toast.success('Appartement active avec succes')
            }
        }

        dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

    }

    const getUnactivatedRentalProperties = async () => {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        let response = await house.getUnactivatedRentalProperties()

        // Set the unactivated furnished flats in the application

        if(response)
        {

            if(response.status == 200)
            {
                setRps(response.data.properties)
            }

        }

        // Switch of the loader

        dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

    }


    const activateRentalProperty = async (e,flat) => {

        e.preventDefault()

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        let response = await house.activateRentalProperty({
            id : flat.id
        })

        if(response)
        {
            if(response.status)
            {
                toast.success('Appartement active avec succes')
            }
        }

        dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

    }

    useEffect(() => {

        if(!callExecuted.current)
        {

            getUnactivatedFurnishedFlats()

            getUnactivatedRentalProperties()

            callExecuted.current = true

        }

    },[])

    return (

        <section className="w-full">

            <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg">
                <MapIcon className="w-6 inline-flex text-black"/>
                <span className="font-bold pl-2 text-black">Maisons en attente d'activation</span>
            </header>

            {store.loading && <GlobalLoader/>}
            
            <article className='mt-10'>

                <header className='text-lg font-bold'>
                    Appartements en attente d'activation
                </header>

                <div className=''>

                <table className="w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">#</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Proprietaire</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Prix journalier</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Salons</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Chambres</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Douches</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Action</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                    {
                        flats.length > 0 ? 

                        flats.map((flat,index) => (
                            <tr key={flat.id}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {index + 1}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{flat.owner?.c_name}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{flat.daily_price}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{flat.saloon}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{flat.rooms}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{flat.bath}</td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex gap-3">
                                    
                                    <a onClick={e => activateFlat(e,flat)} href="" className="text-indigo-600 hover:text-indigo-900">
                                        <CheckBadgeIcon className='w-6'/>
                                    </a>

                                </td>
                            </tr>
                        ))

                        :

                        <tr className=''>Aucun appartement en attente</tr>

                    }
                    </tbody>
                </table>

                </div>

            </article>

            <article className='mt-10'>

                <header className='text-lg font-bold'>
                    Maison locative en attente d'activation
                </header>

                <div className=''>

                <table className="w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">#</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Proprietaire</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Prix mensuel</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Salons</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Chambres</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Douches</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Action</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                    {
                        flats.length > 0 ? 

                        rps.map((flat,index) => (
                            <tr key={flat.id}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {index + 1}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{flat.owner?.c_name}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{flat.monthly_price}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{flat.saloon}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{flat.rooms}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{flat.bath}</td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex gap-3">
                                    
                                    <a onClick={e => activateRentalProperty(e,flat)} href="" className="text-indigo-600 hover:text-indigo-900">
                                        <CheckBadgeIcon className='w-6'/>
                                    </a>

                                </td>
                            </tr>
                        ))

                        :

                        <tr className=''>Aucune location en attente</tr>

                    }
                    </tbody>
                </table>

                </div>

            </article>
            

        </section>

    )

}