import { useContext, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GlobalLoader from "../GlobalLoader";
import useRequest from '../../hooks/useRequest';
import { ADD_PRODUCT } from '../../resources/routes';
import { AppStore } from '../../App';

export default function AddProduct({visible_context})
{

    const {store,dispatch} = useContext(AppStore)

    const {postData,getData} = useRequest()

    const [name,setName] = useState(null)

    const handleTouch = (e) => {

        if(e.target == document.getElementById('add-product-body'))
        {
            dispatch({type : 'TOGGLE_PRODUCT_ADD_INTERFACE', payload : false})
        }

    }

    const handleSubmit = async e => {

        e.preventDefault()

        if(!name)
        {
            toast.warning('Veuillez fournir le nom du produit')
        }
        else{

            dispatch({type : 'TOGGLE_PRODUCT_PAGE_LOADER', payload : true})

            let data  = {
                name : name
            }

            let response = await postData(data,ADD_PRODUCT)

            if(response.status == 200)
            {

                toast.success('Produit ajouté avec succès')

                setTimeout(() => window.location.reload(), 2000);

            }
            else{
                alert(JSON.stringify(response))
            }

            dispatch({type : 'TOGGLE_PRODUCT_PAGE_LOADER', payload : false})

        }

    }

    return (

        <section id="add-product-body" onClick={handleTouch} className="fixed inset-0 bg-gray-900 bg-opacity-75 z-50 flex items-center justify-center">

            <article className="lg:w-7/12 bg-white p-4 overflow-y-auto h-[400px]">

                <form onSubmit={handleSubmit} name="validationForm">

                {store.products.loading && <GlobalLoader/>}

                <ToastContainer/>

                    <header className="font-bold text-lg">Ajouter un produit</header>

                    <div className="pb-4">
                        <label className="block py-4">Nom du produit</label>
                        <input type="text" name="color" onChange={e => setName(e.target.value)} className="bg-transparent border border-gray-400 p-3 w-full focus:outline-none"  placeholder="Nom du produit"/>
                    </div>

                    <div>
                        <button type="submit" className="bg-[#2e7ba5] p-2 pr-10 text-white">Effectuer</button>
                    </div>
                    
                </form>

            </article>

        </section>

    )

}