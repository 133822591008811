
import { getData } from "../lib/request"
import { GET_ERRAND, GET_ERRANDS } from "../resources/routes.js"

export default class errands {

    static async getErrands(){

        let route = GET_ERRANDS

        let response = await getData(route)

        return response

    }

    static async getErrand(id){

        let route = GET_ERRAND + `/${id}`

        let response = await getData(route)

        // alert(JSON.stringify(response.data.errand))

        return response

    }

}