import React from "react";

export default function useRole()
{

    const refs = [
        'admin',
        'conducteur',
        'client',
        'agent',
        'Fournisseur de véhicule'
    ]

    const setRole = (ref) => {

        let element = refs.filter(item => item == ref)

        return element[0]

    }

    return {setRole}

}