import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "../../services/AuthProvider.service";

import {
    BriefcaseIcon,
    UserIcon,
    GlobeAltIcon,
    BuildingOffice2Icon,
    BellAlertIcon,
    PlusCircleIcon,
    TrashIcon,
    QuestionMarkCircleIcon,
    PhoneIcon,
    EnvelopeIcon,
    MapIcon,
    EyeIcon
  } from '@heroicons/react/24/outline'
import useRequest from "../../hooks/useRequest";
import { DELETE_SECTOR, DELETE_VENTURE, GET_ADS, GET_SECTORS, GET_VENTURES } from "../../resources/routes";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import GlobalLoader from "../../components/GlobalLoader";
import AddSector from "../../components/Emploi/AddSector";
import AddVenture from "../../components/Emploi/AddVenture";
import CreateAds from "../../components/Emploi/CreateAds";
import UpdateVenture from "../../components/Emploi/UpdateVenture";
import { AppStore } from "../../App";

// const AddSectorContext = createContext({
//     displaySector : null,
//     setDisplaySector: () => {}
// });

// const AddVentureContext = createContext({
//     displayVenture : null,
//     setDisplayVenture: () => {}
// });

// const AddAdsContext = createContext({
//     displayAds : null,
//     setDisplayAds: () => {}
// });

// const SectorContext = createContext({
//     sectors : null,
//     setSectors : () => {}
// })

// const VentureContext = createContext({
//     ventures : null,
//     setVentures : () => {}
// })

// const UpdateVentureCtx = createContext({
//     updateVenture : false,
//     setUpdateVenture : () => {}
// })

// const ChoosenVentureCtx = createContext({
//     choosenVenture : null,
//     setChoosenVenture : () => {}
// })

// const AdsContext = createContext({
//     ads : [],
//     setAds : () => {}
// })

export default function Emploi({ctx_memo})
{

    const {store,dispatch} = useContext(AppStore)

    const [sectors,setSectors] = useState([])

    const [ventures,setVentures] = useState([])

    const [ads,setAds] = useState([])

    const { postData, getData } = useRequest()

    const [loading,setLoading] = useState(false)

    const [displaySector,setDisplaySector] = useState(false)

    const [displayVenture,setDisplayVenture] = useState(false)

    const [displayAds,setDisplayAds] = useState(false)

    // State, memo useful for venture update

    const [updateVenture,setUpdateVenture] = useState(false)

    const v_updateMemo = useMemo(() => ({updateVenture:updateVenture,setUpdateVenture:setUpdateVenture}))

    const [choosenVenture,setChoosenVenture] = useState(null)

    const choosenVentureMemo = useMemo(() => ({choosenVenture:choosenVenture,setChoosenVenture:setChoosenVenture}))

    const adsMemo = useMemo(() => ({ads:ads,setAds:setAds}))

    const handleVentureInterfaceDisplay = () => {

        if(sectors.length > 0)
        {
            setDisplayVenture(true)
        }
        else{
            toast.warning('Veuillez ajouter au moins un secteur d\'activité')
        }

    }

    const handleAdInterfaceDisplay = () => {

        ventures.length > 0 ? setDisplayAds(true) : toast.warning('Veuillez ajouter une entreprise avant de continuer')

    }

    const fetch_sectors = async () => {

        dispatch({type : 'SET_EMPLOYMENT_LOADING', payload : true})

        let response = await getData(GET_SECTORS)

        if(response.status == 200)
        {
            dispatch({type : 'SET_SECTORS', payload : response.data.sectors})
        }
        else{
            toast.error('Sectors fetching went wrong')

            alert(JSON.stringify(response))
        }

    }

    const fetch_ventures = async () => {

        let response = await getData(GET_VENTURES)

        if(response.status == 200)
        {
            dispatch({type : 'SET_ENTERPRISES', payload : response.data.ventures})
        }
        else{
            toast.error('Ventures fetching went wrong')
        }

    }

    const fetch_ads = async () => {

        dispatch({type : 'SET_EMPLOYMENT_LOADING', payload : false})

        let response = await getData(GET_ADS)

        if(response.status == 200)
        {
            dispatch({type : 'SET_ADS', payload : response.data.ads})
        }
        else{
            toast.error('Ads fetching went wrong')
        }

    }

    const handleDeleteSector = async id => {

        // Handle sector deletion

        if(window.confirm('Voulez vous vraiment supprimer ce secteur ?'))
        {

            dispatch({type : 'SET_EMPLOYMENT_LOADING', payload : true})

            let response = await postData({},`${DELETE_SECTOR}/${id}`)
    
            if(response.status == 200)
            {
                toast.success('Secteur supprimé avec succès')
                fetch_sectors()
                .then(() => dispatch({type : 'SET_EMPLOYMENT_LOADING', payload : false}))
            }
            else{
                toast.error('Something went wrong')
                dispatch({type : 'SET_EMPLOYMENT_LOADING', payload : false})
            }

        }

    }

    const handleDeleteVenture = async id => {

        if(window.confirm('Voulez vous vraiment supprimer ce secteur ?'))
        {

            dispatch({type : 'SET_EMPLOYMENT_LOADING', payload : true})

            let response = await postData({},`${DELETE_VENTURE}/${id}`)
    
            if(response.status == 200)
            {
                toast.success('Secteur supprimé avec succès')
                fetch_ventures()
                .then(() => dispatch({type : 'SET_EMPLOYMENT_LOADING', payload : false}))
            }
            else{
                toast.error('Something went wrong')
                dispatch({type : 'SET_EMPLOYMENT_LOADING', payload : false})
            }

        }

    }

    const handleVentureUpdateInterfaceDisplay = id => {

        try {

            let venture = ventures.filter(venture => venture.id == id)

            setChoosenVenture(...venture)

            setUpdateVenture(true)
            
        } catch (error) {
            toast.error(error.message)
        }

    }

    useEffect(() => {

        fetch_sectors().then(() => fetch_ventures().then(() => fetch_ads()))

    },[])

    return (
        
        <section className="w-full">

            <ToastContainer/>

            {store.employment.loading && <GlobalLoader/>}

            {
                store.employment.sectors.interfaceVisible &&  <AddSector/>
            }

            {
                store.employment.enterprises.interfaceVisible && <AddVenture/>
            }

            {
                store.employment.ads.interfaceVisible && <CreateAds/>
            }

            {/* {
                store.employment.enterprises.interfaceVisible && <AddVenture display_context={AddVentureContext} sector_context={SectorContext} venture_context={VentureContext}/>
            }

            {
                store.employment.ads.interfaceVisible && <CreateAds ventures_ctx={VentureContext} ads_ctx={AdsContext} display_context={AddAdsContext}/>
            } */}

            <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg mb-14 flex justify-between">
                <div className="">
                    <BriefcaseIcon className="w-6 inline-flex text-black"/>
                    <span className="font-bold pl-2 text-black">Annonces</span>
                </div>
                {/* <div className="">
                    <button className="bg-blue-800 px-4 py-2 text-sm rounded cursor-pointer text-white ml-5 inline-flex items-center justify-center">Ajouter +</button>
                </div> */}
            </header>

            <article className="">

                <div className="grid lg:grid-cols-3 gap-6">

                    <div className="shadow p-4 shadow-current bg-[#6e1d1d] rounded">

                        <div className="">
                            <GlobeAltIcon className="w-6 inline-flex text-[#b34848]"/>
                            <span className="font-bold pl-2 text-[#b34848]">Secteurs</span>
                        </div>

                        <div className="text-[#b34848] py-4 font-extrabold text-2xl">
                            <span>{sectors.length > 9 ? sectors.length : `0${store.employment.sectors.list.length}`}</span>
                        </div>

                        <div className="">
                            <button onClick={() => dispatch({type : 'SET_SECTOR_INTERFACE_VISIBLE', payload : true})} className="bg-[#b34848] text-gray-300 inline-block px-2 py-2 rounded lg:text-sm">
                                <span className="pr-1">Créer</span>
                                <PlusCircleIcon className="w-4 text-gray-300 inline-block"/>
                            </button>
                        </div>

                    </div>

                    <div className="shadow p-4 shadow-current bg-[#1d576e] rounded">

                        <div className="">
                            <BuildingOffice2Icon className="w-6 inline-flex text-[#4c92ad]"/>
                            <span className="font-bold pl-2 text-[#4c92ad]">Entreprises</span>
                        </div>

                        <div className="text-[#4c92ad] py-4 font-extrabold text-2xl">
                            <span>{ventures.length > 9 ? ventures.length : `0${store.employment.enterprises.list.length}`}</span>
                        </div>

                        <div className="">
                            <button onClick={() => dispatch({type : 'SET_ENTERPRISES_INTERFACE_VISIBLE', payload : true})} className="bg-[#4c92ad] text-gray-300 inline-block px-2 py-2 rounded lg:text-sm">
                                <span className="pr-1">Créer</span>
                                <PlusCircleIcon className="w-4 text-gray-300 inline-block"/>
                            </button>
                        </div>

                    </div>

                    <div className="shadow p-4 shadow-current bg-[#1d6e35] rounded">

                        <div className="">
                            <BellAlertIcon className="w-6 inline-flex text-[#45ac64]"/>
                            <span className="font-bold pl-2 text-[#45ac64]">Annonces</span>
                        </div>

                        <div className="text-[#45ac64] py-4 font-extrabold text-2xl">
                            <span>{ads.length > 9 ? ads.length : `0${store.employment.ads.list.length}`}</span>
                        </div>

                        <div className="">
                            <button onClick={() => dispatch({type : 'SET_ADS_INTERFACE_VISIBLE', payload : true})} className="bg-[#45ac64] text-gray-300 inline-block px-2 py-2 rounded lg:text-sm">
                                <span className="pr-1">Créer</span>
                                <PlusCircleIcon className="w-4 text-gray-300 inline-block"/>
                            </button>
                        </div>

                    </div>

                </div>

            </article>

            <article className="">

                <header className="font-extrabold my-10">
                    <span>Secteurs d'activités</span>
                </header>

                <div className="">
                    
                    {
                        store.employment.sectors.list.length > 0 ? 
                        <div className="grid lg:grid-cols-4 gap-4">

                            {store.employment.sectors.list.map(sector => (
                                <div className="p-3 shadow-current text-black shadow-sm flex items-center">
                                    <div className="w-9/12">
                                        <span className="">{sector.name}</span>
                                    </div>
                                    <div className="w-3/12">
                                        <button className="bg-red-700 text-white p-3" onClick={()=> handleDeleteSector(sector.id)}>
                                            <TrashIcon className="w-4"/>
                                        </button>
                                    </div>
                                </div>
                            ))}

                        </div>:
                        <div className=" italic flex items-center bg-gray-700 text-white p-3 w-8/12 rounded">
                            <QuestionMarkCircleIcon className="w-4 inline-block"/>
                            <span className="pl-2">
                                Aucun secteur d'activité existant
                            </span>
                        </div>
                    }

                </div>

            </article>

            <article className="">

                <header className="font-extrabold my-10">
                    <span>Entreprises</span>
                </header>

                {
                    store.employment.enterprises.update.interfaceVisible && <UpdateVenture/>
                }

                <div className="">
                    
                    {
                        store.employment.enterprises.list.length > 0 ? 
                        <div className="grid lg:grid-cols-4 gap-4">

                            {store.employment.enterprises.list.map(venture => (
                                <div className="p-3 shadow-current text-black shadow-sm">
                                    <div className="py-4">
                                        <div className="mb-2 font-extrabold text-lg">
                                            <span className="">{venture.name} <span className="text-red-800 text-xs italic font-sans">{`(${venture.sector.name})`}</span></span>
                                        </div>
                                        <div className="mb-2 italic text-sm">
                                            <PhoneIcon className="w-4 inline-block"/>
                                            <span className=""> : {venture.phone ? venture.phone : 'Nom spécifié'}</span>
                                        </div>
                                        <div className="mb-2 italic text-sm">
                                            <EnvelopeIcon className="w-4 inline-block"/>
                                            <span className=""> : {venture.email ? venture.email : 'Nom spécifié'}</span>
                                        </div>
                                        <div className="mb-2 italic text-sm">
                                            <MapIcon className="w-4 inline-block"/>
                                            <span className=""> : {venture.location ? venture.location : 'Nom spécifié'}</span>
                                        </div>
                                    </div>
                                    <div className="w-3/12 flex gap-4">
                                        <button onClick={() => handleDeleteVenture(venture.id)} className="bg-red-700 text-white p-3">
                                            <TrashIcon className="w-4"/>
                                        </button>
                                        <button onClick={() => dispatch({type : 'SAVE_UPDATE_DATA', payload : {data : venture,status : true}})} className="bg-blue-700 text-white p-3">
                                            <EyeIcon className="w-4"/>
                                        </button>
                                    </div>
                                </div>
                            ))}

                        </div>:
                        <div className=" italic flex items-center bg-gray-700 text-white p-3 w-8/12 rounded">
                            <QuestionMarkCircleIcon className="w-4 inline-block"/>
                            <span className="pl-2">
                                Aucune entreprise existante
                            </span>
                        </div>
                    }

                </div>

            </article>

            <article className="">

                <header className="font-extrabold my-10">
                    <span>Annonces</span>
                </header>

                <div className="">
                    
                    {
                        store.employment.ads.list.length > 0 ? 
                        <div className="grid lg:grid-cols-4 gap-4">

                            {store.employment.ads.list.map(ad => (
                                <div className="p-3 shadow-current text-black shadow-sm">

                                    <div className="">
                                        <span className="text-red-700 italic pb-4 inline-block">
                                            Annonce de {ad.venture.name}
                                        </span>
                                    </div>

                                    <div className="">
                                        <img src={ad.document} alt="Alt"/>
                                    </div>

                                    <div className="">
                                        {ad.content.split(/\s+/).slice(0, 25).join(' ')}[...]
                                    </div>

                                    <div className="mt-4">
                                        <button className="bg-red-700 text-white p-3">
                                            <TrashIcon className="w-4"/>
                                        </button>
                                        <button className="bg-blue-700 text-white p-3 ml-4">
                                            <EyeIcon className="w-4"/>
                                        </button>
                                    </div>
                                    
                                </div>
                            ))}

                        </div>:
                        <div className=" italic flex items-center bg-gray-700 text-white p-3 w-8/12 rounded">
                            <QuestionMarkCircleIcon className="w-4 inline-block"/>
                            <span className="pl-2">
                                Aucune annonce existante
                            </span>
                        </div>
                    }

                </div>

            </article>
            
        </section>

    )

}