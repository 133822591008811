import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { userService } from "../../services/user.service"
import { AppStore } from "../../App";
import GlobalLoader from "../../components/GlobalLoader";
import { DocumentCheckIcon, InformationCircleIcon, ListBulletIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { SITE_URL } from "../../resources/env";
import ImageZoom from "../../components/ImageZoom";

export default function UserDetails()
{

    const {store,dispatch} = useContext(AppStore)

    const [link,setLink] = useState(null)

    const [commission,setCommission] = useState()

    const params = useParams()

    const fetchUserInfos = async () => {

        let data = {
            id : params.id
        }

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        userService.getUser(data)
        .then(response => {

            // alert(JSON.stringify(response))

            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

            if(response.status == 200)
            {
                dispatch({type : 'SET_USER_INFOS', payload : response.data.user})
                setCommission(response.data.user.commission ?? 0)
            }
            else{
                alert('Something went wrong while fetching user info')
            }

        })

    }

    const togglePsCommission = async () => {

        let data = {
            user_id : params.id
        }

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        userService.activatePersonalizedCommission(data)
        .then(response => {

            // alert(JSON.stringify(response))

            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

            if(response.status == 200)
            {
                dispatch({type : 'SET_USER_INFOS', payload : response.data.user})
            }
            else{
                alert('Something went wrong')
            }

        })

    }

    const setZoom = (e,link) => {

        e.preventDefault()

        dispatch({type : 'SET_ZOOM',payload : true})

        setLink(link)

    }

    useEffect(() => {

        fetchUserInfos()

    },[])

    return (

        <React.Fragment>

            {store.loading && <GlobalLoader/>}

            {
                !store.loading &&

                <section className="w-full">

                    <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg flex items-center">
                        <InformationCircleIcon className="w-6 inline-flex text-black"/>
                        <span className="font-bold pl-2 text-black">Informations de l'utilisateur</span>
                    </header>

                    <div className="my-5">
                        <b>Nom complet : </b> <span>{store.userDetails.infos.c_name}</span>
                    </div>

                    <div className="my-5">
                        <b>Email : </b> <span>{store.userDetails.infos.email}</span>
                    </div>

                    <div className="my-5">
                        <b>Numero de telephone : </b> <span>+229 {store.userDetails.infos.phone}</span>
                    </div>

                    <div className="my-5">
                        <b>Commission : </b> <span> {store.userDetails.infos.commission ?? 0}%</span>
                    </div>

                    <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg flex items-center my-10">
                        <DocumentCheckIcon className="w-6 inline-flex text-black"/>
                        <span className="font-bold pl-2 text-black">Documents</span>
                    </header>

                    <div className="my-5 grid grid-cols-2">

                        {
                            store.userDetails.zoomActive && <ImageZoom link={link}/>
                        }

                        {/* {JSON.stringify(store.userDetails.infos)} */}
                        
                        {
                            store.userDetails.infos.id_card_recto != '' && 

                            <div className="">

                                <header className="font-bold text-lg my-5">Carte d'identite</header>

                                <div className="flex gap-5">

                                    <div className="relative">
                                        <img src={`${store.userDetails.infos.id_card_recto}`}/>
                                        <div className="absolute inset-0 bg-black/40 flex items-center justify-center">
                                            <a onClick={e => setZoom(e,`${store.userDetails.infos.id_card_recto}`)}  className=" bg-blue-700 w-10 h-10 flex items-center justify-center cursor-pointer text-white rounded-full">
                                                <PlusCircleIcon className="w-5"/>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="relative">
                                        <img src={`${store.userDetails.infos.id_card_verso}`}/>
                                        <div className="absolute inset-0 bg-black/40 flex items-center justify-center">
                                            <a onClick={e => setZoom(e,`${store.userDetails.infos.id_card_verso}`)}  className=" bg-blue-700 w-10 h-10 flex items-center justify-center cursor-pointer text-white rounded-full">
                                                <PlusCircleIcon className="w-5"/>
                                            </a>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        }

                        {
                            store.userDetails.infos.passport_pic != '' && 

                            <div className="">

                                <header className="">Passport</header>

                                <div className="">
                                    <img src={`${SITE_URL}/${store.userDetails.infos.passport_pic}`}/>
                                </div>

                            </div>

                        }

                    </div>

                    <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg flex items-center my-10">
                        <ListBulletIcon className="w-6 inline-flex text-black"/>
                        <span className="font-bold pl-2 text-black">Actions</span>
                    </header>

                    <div className="flex gap-3">

                        <form className="" onSubmit={e => {

                            e.preventDefault()

                            dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

                            userService.activatePersonalizedCommission({
                                user_id : params.id
                            })
                            .then(response => {

                                dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

                                if(response)
                                {

                                    if(response.status == 200)
                                    {
                                        // Dispatch the user details
                                        dispatch({type : 'SET_USER_INFOS', payload : response.data.user})
                                    }

                                }
                                else{
                                    console.log('Check internet connexion')
                                }

                            })

                        }}>

                            <div className="pb-4 flex items-center gap-2">

                                <button onClick={togglePsCommission} type="submit" className={`${store.userDetails.infos.personalized_commission_activated ? 'bg-red-700' : 'bg-green-700' } p-3 text-white rounded`}>
                                    {
                                        store.userDetails.infos.personalized_commission_activated ? 'Desactiver la commission personnalisee' : 'Activer la commission personnalisee'
                                    }
                                </button>
                                
                            </div>

                        </form>

                        <form className="" onSubmit={e => {

                            e.preventDefault()

                            let data = {
                                id : params.id
                            }

                            dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

                            userService.lockUserAccount(data)
                            .then(response => {

                                // alert(JSON.stringify(response))

                                dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

                                if(response)
                                {

                                    if(response.status == 200)
                                    {
                                        // Dispatch the user details
                                        dispatch({type : 'SET_USER_INFOS', payload : response.data.user})
                                    }

                                }
                                else{
                                    console.log('Check internet connexion')
                                }

                            })

                        }}>

                            <button type="submit" className={`${store.userDetails.infos.account_locked ? 'bg-red-700' : 'bg-green-700' } p-3 text-white rounded`}>
                                {
                                    store.userDetails.infos.account_locked ? 'Debloquer le compte' : 'Bloquer le compte'
                                }
                            </button>

                        </form>

                    </div>

                    {
                        store.userDetails.infos.personalized_commission_activated && 
                        <form className="w-8/12" onSubmit={e => {

                            e.preventDefault()

                            let data = {
                                user_id : params.id,
                                commission : commission
                            }

                            dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

                            userService.setDriverCommission(data)
                            .then(response => {

                                dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

                                // alert(JSON.stringify(response))
                                
                                if(response)
                                {

                                    if(response.status == 200)
                                    {
                                        // alert(JSON.stringify(response.data.user))
                                        dispatch({type : 'SET_USER_INFOS', payload : response.data.user})
                                    }

                                }
                                else{
                                    console.log('Check your internet connexion')
                                }

                            })

                        }}>
                            
                            <div className="">

                                <label className="block my-3">Definir la commission (%)</label>
                                <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={commission} onChange={e => setCommission(e.target.value)}  type="text" placeholder=""/>

                            </div>

                            <div className="mt-5">
                                <button type="submit" className={`bg-blue-700 p-3 text-white rounded`}>Valider</button>
                            </div>

                        </form>
                    }

                </section>

            }

        </React.Fragment>

    )

}