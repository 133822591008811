import React, { useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GlobalLoader from "../GlobalLoader";
import { useState } from 'react';
import { AppStore } from '../../App';
import categories from "../../services/categories.service"


export default function CreateCategory()
{

    const {store,dispatch} = useContext(AppStore)

    const [name,setName] = useState('')
    const [baseDistance,setBaseDistance] = useState('')
    const [kmPrice,setKmPrice] = useState('')
    const [baseDistancePrice,setBaseDistancePrice] = useState('')
    const [minPrice,setMinPrice] = useState('')

    const handleTouch = (e) => {

        if(e.target == document.getElementById('add-product-body'))
        {
            dispatch({type : 'TOGGLE_CATEGORY_CREATION_INTERFACE', payload : false})
        }

    }

    const handleSubmit = async e => {

        e.preventDefault()

        let data = {
            name : name,
            base_distance : baseDistance,
            price : baseDistancePrice,
            kilometer_price : kmPrice,
            minute_price : minPrice,
            minute_pricing : minPrice
        }

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        categories.createCategory(data)
        .then(response => {

            // alert(JSON.stringify(response))

            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

            if(response.status == 200)
            {
                dispatch({type : 'SET_CATEGORIES',payload : response.data.categories})
                toast.success('Categorie ajoute avec succes')
                dispatch({type : 'TOGGLE_CATEGORY_CREATION_INTERFACE', payload : false})
            }
            else{
                toast.error('Erreur lors de la creation de la categorie')
                dispatch({type : 'TOGGLE_CATEGORY_CREATION_INTERFACE', payload : false})
            }

        })

    }

    return (

        <section id="add-product-body" onClick={handleTouch} className="fixed inset-0 bg-gray-900 bg-opacity-75 z-50 flex items-center justify-center">

            <article className="lg:w-7/12 bg-white p-4 overflow-y-auto h-[400px]">

                <form onSubmit={handleSubmit} name="validationForm">

                {store.loading && <GlobalLoader/>}

                <ToastContainer/>

                    <header className="font-extrabold text-xl mb-6">Ajouter une categorie</header>

                    <div className="pb-4">
                        <label className="block py-2">Nom de la categorie</label>
                        <input type="text" onChange={e => setName(e.target.value)} className="bg-transparent border border-gray-400 p-2 w-full focus:outline-none"  placeholder="Nom de la categorie"/>
                    </div>

                    <div className="pb-4">
                        <label className="block py-2">Distance de base</label>
                        <input type="number" onChange={e => setBaseDistance(e.target.value)} className="bg-transparent border border-gray-400 p-2 w-full focus:outline-none"  placeholder="Distance de base"/>
                    </div>

                    <div className="pb-4">
                        <label className="block py-2">Prix de la distance de base</label>
                        <input type="number" step={`0.01`} onChange={e => setBaseDistancePrice(e.target.value)} className="bg-transparent border border-gray-400 p-2 w-full focus:outline-none"  placeholder="Prix de la distance de base"/>
                    </div>

                    <div className="pb-4">
                        <label className="block py-2">Prix du KM</label>
                        <input type="number" step={`0.01`} onChange={e => setKmPrice(e.target.value)} className="bg-transparent border border-gray-400 p-2 w-full focus:outline-none"  placeholder="Prix du KM"/>
                    </div>

                    <div className="pb-4">
                        <label className="block py-2">Prix a la minute</label>
                        <input type="number" step={`0.01`} onChange={e => setMinPrice(e.target.value)} className="bg-transparent border border-gray-400 p-2 w-full focus:outline-none"  placeholder="Prix a la minute"/>
                    </div>

                    <div>
                        <button type="submit" className="bg-[#2e7ba5] p-2 pr-10 text-white">Effectuer</button>
                    </div>
                    
                </form>

            </article>

        </section>

    )

}