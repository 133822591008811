import { CurrencyDollarIcon, EyeIcon } from "@heroicons/react/24/outline"
import React, { useContext, useEffect } from "react"
import { AppStore } from "../../App"
import Spinner from "../../components/Spinner"
import house from "../../services/house.service"
import TaxePaymentHistory from "../../components/Taxe/History"

export default function Taxes()
{

    const {store,dispatch} = useContext(AppStore)

    const fetchHouses = async () => {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        house.getHouses()
        .then(response => {

            // alert(JSON.stringify(response))

            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

            if(response != undefined)
            {

                if(response.status == 200)
                {
                    // Dispatch the data in the app to show them later on
                    dispatch({type : 'SET_TAXABLE_HOUSES',payload : response.data.houses})
                }
                else{
                    console.log(response)
                }

            }
            else{
                console.log('Response is undefined')
            }

        })

    }

    const payTaxe = async (e,house_id) => {

        e.preventDefault()

        let data = {
            house_id : house_id
        }

        if(window.confirm('Voulez vous vraiment proceder a cette operation ?'))
        {

            dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

            house.payTaxe(data)
            .then(response => {

                // alert(JSON.stringify(response))

                dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

                if(response != undefined)
                {

                    if(response.status == 200)
                    {
                        // Dispatch the data in the app to show them later on
                        dispatch({type : 'SET_TAXABLE_HOUSES',payload : response.data.houses})
                    }
                    else{
                        console.log(response)
                    }

                }
                else{
                    console.log('Response is undefined')
                }

            })

        }

    }

    const computeTaxes = payments => {

        let tamount = 0

        for(var i = 0;i < payments.length;i++)
        {
            tamount = payments[i].amount
        }

        return tamount

    }

    const callHistoryInterface = history => {

        // alert(JSON.stringify(history))

        dispatch({type : 'SET_TAXE_HISTORY',payload : history})

        dispatch({type : 'TOGGLE_HISTORY_INTERFACE',payload : true})

    }

    useEffect(() => {

        fetchHouses()

    },[])

    return (

        <section className="w-full">

            <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg mb-14 flex justify-between">
                <div className="">
                    <CurrencyDollarIcon className="w-6 inline-flex text-black"/>
                    <span className="font-bold pl-2 text-black">Impots</span>
                </div>
                {/* <div className="">
                    <button className="bg-blue-800 px-4 py-2 text-sm rounded cursor-pointer text-white ml-5 inline-flex items-center justify-center">Ajouter +</button>
                </div> */}
            </header>

            {
                store.loading && <Spinner/>
            }

            {store.taxes.historyInterfaceVisible && <TaxePaymentHistory/>}

            {
                !store.loading && 
                
                <table className="w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        #
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Maison
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Proprietaire
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Impot a nnuel
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Impot a payer
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Payer
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Action
                        </th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                    {store.taxes?.houses.map((house,index) => (
                        <tr key={house.id}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {index + 1}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{house.label}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{house.landlord.c_name}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{house.taxe}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {/* Compute the current taxes money there */}
                                {Math.floor(computeTaxes(house.payments))} <span className="font-bold text-xs">FCFA</span>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <button onClick={e => payTaxe(e,house.id)} className="bg-green-800 text-white font-semibold p-2 rounded-md">
                                    Payer
                                </button>
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6">
                                <button onClick={e => callHistoryInterface(house.payment_history)} className="bg-blue-800 text-white font-semibold p-2 rounded-md flex items-center gap-2">
                                    <EyeIcon className="w-4"/>
                                    Historique des paiements
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>

            }

        </section>

    )

}