import React from "react"
import { GET_BOOKINGS, VALIDATE_BOOKING } from "../resources/routes"
import { getData , postData} from "../lib/request"


export default class booking {

    static async getBookings ()
    {

        let response = await getData(GET_BOOKINGS)

        return response

    }

    static async validateBooking (id)
    {

        let response = await postData({},VALIDATE_BOOKING + '/' + id)

        return response

    }

}